import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducer';
import InstructorContainer from '../InstructorContainer/InstructorContainer';
import image from '../../img/key-visual.jpeg';
import CategoryContainer from '../CategoryContainer/CategoryContainer';
import { RulesGroupContent } from '../../ui-components/RulesGroupContent/RulesGroupContent';
import { imageAbsolutePath } from '../../utils/utils';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';
import styles from './QuestionsBgContainer.module.css';

const rulesImage = imageAbsolutePath('key-visual.jpeg');
const rulesImageDark = imageAbsolutePath('key-visualDark.png');

const QuestionsBgContainer: React.FC = () => {
  const { isDarkTheme } = useGetSelectedColorTheme();

  const rulesImageSrc = isDarkTheme ? rulesImageDark : rulesImage;

  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);
  const instructor = useSelector(({ user }: IRootState) => user.profile.drivingInstructor);

  if (isLogged && instructor) {
    return (
      <div className={styles.questionsBgContainer}>
        <RulesGroupContent>
          <div className={styles.imageBox}>
            <InstructorContainer src={rulesImageSrc} hideForMobile />
          </div>
          <CategoryContainer />
        </RulesGroupContent>
      </div>
    );
  }
  return (
    <RulesGroupContent>
      <InstructorContainer src={image} hideForMobile />
      <CategoryContainer />
    </RulesGroupContent>
  );
};

export default QuestionsBgContainer;
