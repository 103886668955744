import React, { useCallback } from 'react';
import { IQuestionLinkProps } from './QuestionLink.types';

export const QuestionLink: React.FC<IQuestionLinkProps> = ({
  children,
  className,
  onQuestionChange,
  link
}: IQuestionLinkProps) => {
  const handleQuestionChange = useCallback(() => {
    onQuestionChange(link);
  }, [onQuestionChange, link]);

  return (
    <button type="button" onClick={handleQuestionChange} className={className}>
      {children}
    </button>
  );
};
