import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { selectLanguage } from '../../reducer/user.selectors';

const NavLinkWrapper: React.FC<NavLinkProps> = ({ to, ...props }) => {
  const language = useSelector(selectLanguage);

  return <NavLink {...props} to={`/${language}${to}`} />;
};

export default NavLinkWrapper;
