import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { IQuestionBtnProps } from './QuestionBtn.types';
import { BtnList } from '../BtnList/BtnList';
import styles from './QuestionBtn.module.css';

export const QuestionBtn: React.FC<IQuestionBtnProps> = ({
  isLogged,
  isExam,
  disabled,
  onStartExam,
  currentCategory
}: IQuestionBtnProps) => {
  const { t } = useTranslation();

  const handleExamStart = useCallback(() => {
    if (onStartExam) {
      onStartExam(Date.now());
    }
  }, [onStartExam]);

  return (
    <BtnList>
      {isLogged ? (
        <>
          {!isExam && (
            <Button
              disabled={disabled}
              className={classNames({ [styles.buttonDisable]: disabled })}
              variant="secondary"
            >
              <LinkWrapper
                className={classNames(styles.learnButtonLinkColor, { [styles.buttonDisable]: disabled })}
                to={`/questions/${currentCategory}/all`}
              >
                {t('askContainer.learnNowBtn')}
              </LinkWrapper>
            </Button>
          )}
          {isExam && (
            <Button disabled={disabled} variant="secondary" onClick={handleExamStart}>
              {t('examPage.startExam')}
            </Button>
          )}
        </>
      ) : (
        <>
          <Button variant="primary" target="_blank" href={t('tcsOffersLink')}>
            {t('askContainer.dealsButton')}
          </Button>
          {isExam && (
            <Button disabled={disabled} variant="outline-primary" onClick={handleExamStart}>
              {t('examPage.startExam')}
            </Button>
          )}
          {!isExam && (
            <Button as={LinkWrapper} to={`/questions/${currentCategory}/demo`} variant="outline-primary">
              {t('askContainer.tryItForFreeButton')}
            </Button>
          )}
        </>
      )}
    </BtnList>
  );
};
