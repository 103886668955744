import { UNCALLED } from '../constants/store.constants';

export interface Request {
  status: RequestStatusType;
  error: null | Error | string;
}

export const defaultRequestStatus: Request = {
  status: UNCALLED,
  error: null
};

export type RequestStatusType = 'pending' | 'success' | 'failure' | 'uncalled';
