import React from 'react';
import cn from 'classnames';
import styles from './ZoomBtn.module.css';
import { IZoomBtnProps } from './ZoomBtn.types';

const ZoomBtn: React.FC<IZoomBtnProps> = ({ onClick, className }: IZoomBtnProps) => (
  <button type="button" className={cn(className, styles.zoomBtn)} onClick={onClick}>
    <i className="icon-zoom" />
  </button>
);

export default ZoomBtn;
