import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GET_TOPICS_REQUEST } from '../actions/topic.action';
import ModalByTopic from '../components/ModalByTopic/ModalByTopic';
import IModalByTopicContainerProps from './ModalByTopicContainer.type';
import { IRootState } from '../reducer';
import { selectLanguage } from '../reducer/user.selectors';

const ModalByTopicContainer: React.FC<IModalByTopicContainerProps> = ({
  onHide,
  show
}: IModalByTopicContainerProps) => {
  const { t } = useTranslation();
  const topics = useSelector(({ topic }: IRootState) => topic.topics);
  const language = useSelector(selectLanguage);
  const dispatch = useDispatch();
  const category = useSelector(({ user }: IRootState) => user.profile.currentCategory);

  useEffect(() => {
    if (!topics.length) {
      dispatch(GET_TOPICS_REQUEST({ category, language }));
    }
  }, [dispatch, category, language, topics]);

  return (
    <ModalByTopic
      currentCategory={category}
      title={t('questionsByTopicContainer.aboutTopics')}
      show={show}
      onHide={onHide}
      topics={topics}
    />
  );
};

export default ModalByTopicContainer;
