import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { IAboutPageProps } from './AboutPage.types';
import styles from './AboutPage.module.css';

import { imageAbsolutePath } from '../../utils/utils';

const handshakeIcon = imageAbsolutePath('aboutPage/tcs-icon_handshake.png');
const learnIcon = imageAbsolutePath('aboutPage/tcs-icon_lernen.png');
const startIcon = imageAbsolutePath('aboutPage/tcs-icon_mitgliedschaft_vorteile.png');

const AboutPage: React.FC<IAboutPageProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.aboutPage}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('aboutPage.title')}</PageTitle>
            <div className={styles.aboutPageSubtitle}>{t('aboutPage.subTitle1')}</div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className={styles.aboutPageTextList}>
              <div className={styles.aboutPageItem}>
                <img src={startIcon} alt="hand shake icon" />
                <div>
                  <p className={styles.aboutPageItemTitle}>{t('aboutPage.itemTitle1')}</p>
                  <p className={styles.aboutPageText}>{t('aboutPage.text1')}</p>
                </div>
              </div>
              <div className={styles.aboutPageItem}>
                <img src={learnIcon} alt="learn icon" />
                <div>
                  <p className={styles.aboutPageItemTitle}>{t('aboutPage.itemTitle2')}</p>
                  <p className={styles.aboutPageText}>{t('aboutPage.text2')}</p>
                </div>
              </div>
              <div className={styles.aboutPageItem}>
                <img src={handshakeIcon} alt="hand shake icon" />
                <div>
                  <p className={styles.aboutPageItemTitle}>{t('aboutPage.itemTitle3')}</p>
                  <p className={styles.aboutPageText}>{t('aboutPage.text3')}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageTitle}>{t('aboutPage.title2')}</div>
            <div className={styles.aboutPageBoldSubtitle}>{t('aboutPage.subTitle2')}</div>
            <p className={styles.aboutPageText}>{t('aboutPage.text4')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageSubtitleWithMargin}>{t('aboutPage.subTitle3')}</div>
            <p className={styles.aboutPageText}>{t('aboutPage.text5')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageSubtitleWithMargin}>{t('aboutPage.subTitle4')}</div>
            <p className={styles.aboutPageText}>{t('aboutPage.text6')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.aboutPageSubtitleWithMargin}>{t('aboutPage.subTitle5')}</div>
            <p className={styles.aboutPageText}>{t('aboutPage.text7')}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutPage;
