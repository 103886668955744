import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Container, Col } from 'react-bootstrap';
import Ads from '../../components/Ads/Ads';
import image from '../../img/key-visual.jpeg';

import InstructorContainer from '../InstructorContainer/InstructorContainer';
import ExamDetailsContainer from '../ExamDetailsContainer';
import { IRootState } from '../../reducer';
import styles from './ExamPageContainer.module.css';
import ExamBgContainer from '../ExamBgContainer/ExamBgContainer';

const ExamPageContainer: React.FC = () => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);

  return (
    <>
      <div className={styles.examPage}>
        <Container>
          <Row>
            <Col>
              <ExamBgContainer />
            </Col>
          </Row>
        </Container>
      </div>
      {!isLogged && <Ads />}
      <div className={styles.examDetails}>
        <Container>
          <Row>
            <Col>
              <ExamDetailsContainer />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.mobileInstructor}>
        <Container>
          <Row>
            <Col>
              <InstructorContainer src={image} />
            </Col>
          </Row>
        </Container>
      </div>
      {isLogged && <Ads />}
    </>
  );
};

export default ExamPageContainer;
