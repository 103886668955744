import React from 'react';
import { ICategoryIconProps } from './CategoryIcon.types';
import styles from './CategoryIcon.module.css';

const Icon: React.FC<ICategoryIconProps> = ({ icon, level, badge }: ICategoryIconProps) => (
  <div className={styles.rulesListItem}>
    <div className={styles.rulesListItemBg}>
      <i className={icon} />
      {badge ? <span className={styles.rulesListItemBadge}>e-bike</span> : null}
    </div>
    <span className={styles.rulesListItemText}>{level}</span>
  </div>
);

export default Icon;
