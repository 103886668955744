import * as Yup from 'yup';

export const VerifyVoucherSuccessPayloadSchema = Yup.object().shape({
  voucher: Yup.object()
    .shape({
      code: Yup.string()
        .min(1)
        .required(),
      offerId: Yup.string()
        .min(1)
        .required(),
      teacherId: Yup.string().nullable()
    })
    .required()
});
