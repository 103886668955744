import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ZoomLink.module.css';
import { IZoomLinkProps } from './ZoomLink.types';

const ZoomLink: React.FC<IZoomLinkProps> = ({ onClick }: IZoomLinkProps) => {
  const { t } = useTranslation();
  return (
    <button type="button" className={styles.zoomLink} onClick={onClick}>
      {t('questionCard.originLink')}
    </button>
  );
};

export default ZoomLink;
