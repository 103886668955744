import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoodByeModal from '../components/GoodByeModal/GoodByeModal';
import { GOOD_BYE_MODAL_CLOSE } from '../actions/login.action';
import { IRootState } from '../reducer/index';

const GoodByeModalContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(({ login }: IRootState) => login.isGoodByeModalOpen);
  const handleHideModal = useCallback(() => {
    dispatch(GOOD_BYE_MODAL_CLOSE());
  }, [dispatch]);
  return <GoodByeModal show={isModalOpen} onHide={handleHideModal} />;
};

export default GoodByeModalContainer;
