import React from 'react';
import Ads from '../../components/Ads/Ads';
import CockpitLoggedContainer from '../../containers/CockpitLoggedContainer';
import styles from '../CockpitNotLoggedPage/CockpitNotLoggedPage.module.css';

const CockpitLoggedPage: React.FC = () => {
  return (
    <div className={styles.CockpitLoggedPage}>
      <CockpitLoggedContainer />
      <Ads />
    </div>
  );
};

export default CockpitLoggedPage;
