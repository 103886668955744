import { call, put, takeLatest } from 'redux-saga/effects';
import {
  REMAINING_MEMBER_FAILURE,
  REMAINING_MEMBER_REQUEST,
  REMAINING_MEMBER_SUCCESS,
  VERIFY_MEMBER_FAILURE,
  VERIFY_MEMBER_REQUEST,
  VERIFY_MEMBER_SUCCESS
} from '../actions/verifyMember.action';
import { remainingMemberTCS, verifyMemberTCS } from './verifyMember.api';

function* verifyMember({ payload }) {
  try {
    const response = yield call(verifyMemberTCS, payload);
    if (response.status !== 200) {
      yield put(VERIFY_MEMBER_FAILURE(response?.payload));
    } else {
      yield put(VERIFY_MEMBER_SUCCESS());
    }
  } catch (e) {
    yield put(VERIFY_MEMBER_FAILURE(e));
  }
}

function* remainingMemberCount() {
  try {
    const response = yield call(remainingMemberTCS);
    if (response.status !== 200) {
      yield put(REMAINING_MEMBER_FAILURE(response?.payload));
    } else {
      yield put(REMAINING_MEMBER_SUCCESS(response?.payload));
    }
  } catch (e) {
    yield put(VERIFY_MEMBER_FAILURE(e));
  }
}

function* registerSaga() {
  yield takeLatest(VERIFY_MEMBER_REQUEST, verifyMember);
  yield takeLatest(REMAINING_MEMBER_REQUEST, remainingMemberCount);
}

export default registerSaga;
