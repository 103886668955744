import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import PromoIcon from '../../components/PromoIcon/PromoIcon';
import { selectLanguage } from '../../reducer/user.selectors';
import { imageAbsolutePath } from '../../utils/utils';
import { IItem } from './TCSOffersPage.types';
import styles from './TCSOffersPage.module.css';

const helloIcon = imageAbsolutePath('/TSCOffersPage/helloIcon.jpeg');
const trainIcon = imageAbsolutePath('/TSCOffersPage/trainIcon.jpeg');
const weatherIcon = imageAbsolutePath('/TSCOffersPage/weatherIcon.png');
const weigherIcon = imageAbsolutePath('/TSCOffersPage/weigherIcon.png');
const motorcycleImage = imageAbsolutePath('/TSCOffersPage/motorcycleImage.jpeg');
const teamImage = imageAbsolutePath('/TSCOffersPage/teamPeopleImage.jpeg');
const carsImage = imageAbsolutePath('/TSCOffersPage/carsImage.jpeg');

const ItemWithIcon: React.FC<IItem> = ({ title, text, withButton = false, icon, buttonText, link, alt }) => {
  return (
    <Row className={styles.itemsWrapper}>
      <Col className={styles.itemsImageContainer} lg={1} md={12}>
        <img width={80} src={icon} alt={alt} />
      </Col>
      <Col className={styles.itemTextContainer} lg={8} md={12}>
        <p className={styles.itemTitle}>{title}</p>
        <p className={styles.TSCOffersText}>{text}</p>
      </Col>
      {withButton && (
        <Col className={styles.colButtonWrapper} lg={3} md={12}>
          <Button variant="secondary" target="_blank" href={link}>
            {buttonText}
          </Button>
        </Col>
      )}
    </Row>
  );
};

const ItemWithImage: React.FC<{ title: string; text: string; icon: any; buttonText: string; link: string; alt }> = ({
  title,
  text,
  icon,
  buttonText,
  link,
  alt
}) => {
  return (
    <Row className={styles.itemsWrapper}>
      <Col lg={2}>
        <img src={icon} width="100%" alt={alt} />
      </Col>
      <Col className={styles.itemTextContainer} lg={7}>
        <p className={styles.itemTitle}>{title}</p>
        <p className={styles.TSCOffersText}>{text}</p>
      </Col>
      <Col className={styles.colButtonWrapper} lg={3}>
        <Button variant="secondary" target="_blank" href={link}>
          {buttonText}
        </Button>
      </Col>
    </Row>
  );
};

const TCSOffersPage: React.FC = () => {
  const { t } = useTranslation();

  const currentLanguage = useSelector(selectLanguage);

  return (
    <>
      <div className={styles.TCSOffersPage}>
        <Container className={styles.container}>
          <Row>
            <Col lg={8} md={12}>
              <PageTitle>{t('TCSOffersPage.title')}</PageTitle>
              <p className={styles.TSCOffersText}>{t('TCSOffersPage.text1')}</p>
              <p className={styles.subtitle}>{t('TCSOffersPage.title2')}</p>
              <p className={styles.TSCOffersText}>{t('TCSOffersPage.text2')}</p>
            </Col>
            <Col className={styles.bannerWrapper} lg={4} md={12}>
              <PromoIcon src="4" currentLanguage={currentLanguage} alt="badge" />
            </Col>
          </Row>
          <ItemWithIcon
            alt="icon"
            icon={helloIcon}
            title={t('TCSOffersPage.itemTitle1')}
            text={t('TCSOffersPage.itemText1')}
          />
          <ItemWithIcon
            alt="icon"
            icon={trainIcon}
            title={t('TCSOffersPage.itemTitle2')}
            text={t('TCSOffersPage.itemText2')}
          />
          <ItemWithIcon
            alt="icon"
            icon={weatherIcon}
            title={t('TCSOffersPage.itemTitle3')}
            text={t('TCSOffersPage.itemText3')}
          />
          <ItemWithIcon
            alt="icon"
            icon={weigherIcon}
            title={t('TCSOffersPage.itemTitle4')}
            text={t('TCSOffersPage.itemText4')}
            withButton
            buttonText={t('TCSOffersPage.buttonText')}
            link={t('TCSOffersPage.buttonLink1')}
          />
        </Container>
        <div className={styles.listContainer}>
          <Container>
            <Row>
              <Col>
                <p className={styles.underTitle}>{t('TCSOffersPage.title3')}</p>
              </Col>
            </Row>
            <ItemWithImage
              alt="motorcycle image"
              icon={motorcycleImage}
              title={t('TCSOffersPage.itemTitle5')}
              text={t('TCSOffersPage.itemText5')}
              buttonText={t('TCSOffersPage.buttonText')}
              link={t('TCSOffersPage.buttonLink2')}
            />
            <ItemWithImage
              alt="team image"
              icon={teamImage}
              title={t('TCSOffersPage.itemTitle6')}
              text={t('TCSOffersPage.itemText6')}
              buttonText={t('TCSOffersPage.buttonText')}
              link={t('TCSOffersPage.buttonLink3')}
            />
            <ItemWithImage
              alt="cars image"
              icon={carsImage}
              title={t('TCSOffersPage.itemTitle7')}
              text={t('TCSOffersPage.itemText7')}
              buttonText={t('TCSOffersPage.buttonText')}
              link={t('TCSOffersPage.buttonLink4')}
            />
          </Container>
        </div>
      </div>
    </>
  );
};

export default TCSOffersPage;
