import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import IModalAccessibilityProps from './ModalAccessibility.types';
import styles from './ModalAccessibility.module.css';
import { P3 } from '../../ui-components/P3/P3';

const ModalAccessibility: React.FC<IModalAccessibilityProps> = ({
  show,
  onHide,
  isMultipleTabs,
  isBrowserNotSupported,
  isOffline
}: IModalAccessibilityProps) => {
  const { t } = useTranslation();

  const onReload = useCallback(() => {
    window.location.reload();
  }, []);

  const composeTitle = () => {
    let title = '';
    if (isMultipleTabs) {
      title = t('modalOfflineMode.titleTabs');
    }
    if (isOffline) {
      title = t('modalOfflineMode.title');
    }
    return title;
  };

  return (
    <Modal className={styles.customModal} animation show={show} size="sm" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{composeTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isOffline && <P3>{t('modalOfflineMode.text')}</P3>}
        {isMultipleTabs && <P3>{t('modalOfflineMode.multipleTabs')}</P3>}
        {isBrowserNotSupported && <P3>{t('modalOfflineMode.browserDoesNotSupport')}</P3>}
      </Modal.Body>
      <Modal.Footer>
        {isOffline && (
          <>
            <Button variant="link" onClick={onReload}>
              {t('modalOfflineMode.supportButton')}
            </Button>
            <Button variant="secondary" onClick={onHide}>
              {t('modalOfflineMode.mainButton')}
            </Button>
          </>
        )}
        {(isMultipleTabs || isBrowserNotSupported) && !isOffline && (
          <Button variant="secondary" onClick={onHide}>
            {t('modalOfflineMode.gotItBtn')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAccessibility;
