import { createAction } from 'redux-actions';

export const VERIFY_MEMBER_REQUEST = createAction('VERIFY_MEMBER_REQUEST');
export const VERIFY_MEMBER_FAILURE = createAction('VERIFY_MEMBER_FAILURE');
export const VERIFY_MEMBER_SUCCESS = createAction('VERIFY_MEMBER_SUCCESS');

export const VERIFY_MEMBER_ERROR_RESET = createAction('VERIFY_MEMBER_ERROR_RESET');

export const REMAINING_MEMBER_REQUEST = createAction('REMAINING_MEMBER_REQUEST');
export const REMAINING_MEMBER_FAILURE = createAction('REMAINING_MEMBER_FAILURE');
export const REMAINING_MEMBER_SUCCESS = createAction('REMAINING_MEMBER_SUCCESS');
