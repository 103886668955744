import queryString from 'query-string';
import i18n from 'i18next';
import { GATE } from '../constants';
import { TOKEN } from '../constants/storageKeys';

interface Options {
  method: 'PATCH' | 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers: {
    'Content-Type': string;
    'X-Localization': string;
    Authorization: string;
  };
  body?: any;
}

export interface IApiFailureResponse {
  status: number;
  error: {
    code: number;
    message: string;
    validationError?: object;
    stack?: string;
  };
}

export default function customFetch(
  method: 'PATCH' | 'GET' | 'POST' | 'PUT' | 'DELETE',
  path: string,
  params?: object
) {
  let url = `${GATE}${path}`;
  const token = localStorage.getItem(TOKEN);
  const options: Options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Localization': i18n.language,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
  if ((method === 'POST' || method === 'PATCH' || method === 'PUT') && params) {
    options.body = JSON.stringify(params);
  } else if (method === 'GET' && params) {
    const filterParams = Object.keys(params)
      .filter(key => typeof params[key] !== 'undefined' && params[key] !== null)
      .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});
    if (Object.keys(filterParams).length > 0) {
      url += `?${queryString.stringify(filterParams, { strict: true })}`;
    }
  }

  return fetch(url, options)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.error) {
        throw result;
      }
      return result;
    });
}
