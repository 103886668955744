import { handleActions } from 'redux-actions';
import { ITheoryState } from './theory.types';
import {
  GET_THEORY_REQUEST,
  GET_THEORY_FAILURE,
  GET_THEORY_SUCCESS,
  CHANGE_THEORY_MATCH_PARAMS
} from '../actions/theory.action';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { groupBy } from '../utils/groupBy';

const defaultState: ITheoryState = {
  theory: {
    chapters: [],
    pages: [],
    registers: [],
    pageChapters: {},
    groupPages: {}
  },
  theoryRequest: {
    status: 'uncalled',
    error: null
  },
  page: 1,
  language: 'de'
};

const groupByPages = groupBy('page');

const reduces = handleActions(
  {
    [GET_THEORY_REQUEST]: state => ({
      ...state,
      theoryRequest: { status: PENDING, error: null }
    }),
    [GET_THEORY_FAILURE]: action => ({
      ...defaultState,
      theoryRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_THEORY_SUCCESS]: (state, action) => ({
      ...state,
      theoryRequest: { status: SUCCESS, error: null },
      theory: {
        ...action.payload.theory,
        pageChapters: action.payload.theory.chapters.reduce((acc, chapter) => {
          acc[chapter.page] = chapter.title;
          return acc;
        }, {}),
        groupPages: groupByPages(action.payload.theory.pages)
      },
      language: action.payload.language
    }),
    [CHANGE_THEORY_MATCH_PARAMS]: (state, action) => ({
      ...state,
      page: +action.payload.page
    })
  },
  defaultState
);

export default reduces;
