import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { IQuestionChooseProps } from './QuestionChoose.types';
import QuestionChooseModalContainer from '../../containers/QuestionChooseModalContainer/QuestionChooseModalContainer';

const QuestionChoose: React.FC<IQuestionChooseProps> = ({
  iconCls,
  questionList,
  onQuestionChange,
  isExam,
  finishedExam,
  topic,
  defaultRoute
}: IQuestionChooseProps) => {
  const [showWin, setShowWin] = useState(false);

  const changeModalView = useCallback(() => {
    setShowWin(!showWin);
  }, [showWin, setShowWin]);

  return (
    <button type="button" className={classNames(iconCls)} onClick={changeModalView}>
      <i className="icon-grid" />
      <QuestionChooseModalContainer
        topicOpen
        finishedExam={finishedExam}
        onQuestionChange={onQuestionChange}
        defaultRoute={defaultRoute}
        isExam={isExam}
        questionList={questionList}
        show={showWin}
        topicId={topic}
        onHide={changeModalView}
      />
    </button>
  );
};

export default QuestionChoose;
