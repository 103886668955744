import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { scrollToAnchor } from '../../utils/utils';
import styles from './ScrollToTopButton.module.css';
import useThrottleCallback from '../../hooks/useThrottleCallback';

const windowEventTypes = ['scroll', 'resize'];

const ScrollToTopButton: React.FC = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const widowEventsHandler = useThrottleCallback(() => setIsVisible(window.pageYOffset > window.innerHeight), [], 250);

  useEffect(() => {
    windowEventTypes.forEach(eventType => window.addEventListener(eventType, widowEventsHandler));
    return () => windowEventTypes.forEach(eventType => window.removeEventListener(eventType, widowEventsHandler));
  }, [widowEventsHandler]);

  const handleClick = () => scrollToAnchor('#root');

  return (
    <button type="button" className={cx(styles.btnTop, { [styles.visible]: isVisible })} onClick={handleClick}>
      <i className="icon-arrow" />
      <div className={styles.btnTopText}>{t('toTopButton.text')}</div>
    </button>
  );
};

export default ScrollToTopButton;
