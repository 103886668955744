import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IRegisterVerificationCodeErrorProps } from './RegisterVerificationCodeError.types';
import styles from './RegisterVerificationCodeError.module.css';

export const RegisterVerificationCodeError: FC<IRegisterVerificationCodeErrorProps> = (
  props: IRegisterVerificationCodeErrorProps
) => {
  const { show, onHide, errorMessage } = props;
  const { t } = useTranslation();

  const errorText = errorMessage || 'Unhandled error';

  return (
    <Modal dialogClassName={styles.root} animation onHide={onHide} show={show} size="sm">
      <Modal.Header closeButton className={styles.modalHeader} />
      <Modal.Body className={styles.modalBody}>{errorText}</Modal.Body>
      <Modal.Footer className={styles.controlsContainer}>
        <Button variant="secondary" onClick={onHide}>
          {t('registerForm.form.tcs.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
