import React from 'react';
import { Route } from 'react-router-dom';
import PreludeWithoutSignaling from './PreludeWithoutSignaling';
import PresenceWithSignaling from './PresenceWithSignaling';
import LightSignal from './LightSignal';
import { ITeacher3dPageProps } from './Teacher3d.types';

const Teacher3dRoutes: React.FC<ITeacher3dPageProps> = ({ match }: ITeacher3dPageProps) => (
  <>
    <Route path={`${match.url}`} component={PreludeWithoutSignaling} exact />
    <Route path={`${match.url}/prelude-without-signaling`} component={PreludeWithoutSignaling} />
    <Route path={`${match.url}/presence-with-signaling`} component={PresenceWithSignaling} />
    <Route path={`${match.url}/light-signal`} component={LightSignal} />
  </>
);

export default Teacher3dRoutes;
