import { handleActions } from 'redux-actions';
import { defaultRequestStatus } from './fetch.types';
import { FAILURE, PENDING, SUCCESS } from '../constants/store.constants';
import { ICategoryState } from './category.types';
import { GET_CATEGORY_FAILURE, GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS } from '../actions/category.action';

const defaultState: ICategoryState = {
  categoryRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_CATEGORY_REQUEST]: () => ({
      ...defaultState,
      categoryRequest: { status: PENDING, error: null }
    }),
    [GET_CATEGORY_FAILURE]: (state, action) => ({
      ...defaultState,
      categoryRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_CATEGORY_SUCCESS]: (state, action) => ({
      category: action.payload.category,
      categoryRequest: { status: SUCCESS, error: null }
    })
  },
  defaultState
);

export default reduces;
