import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { SET_USER_SELECTED_CATEGORY } from '../actions/user.action';
import { PageTitle } from '../ui-components/PageTitle/PageTitle';
import { RulesGroupItem } from '../ui-components/RulesGroupItem/RulesGroupItem';
import { CategoryList } from '../components/CategoryList/CategoryList';
import { IRootState } from '../reducer';
import { LinkWrapper } from '../ui-components/LinkWrapper/LinkWrapper';
import styles from './CockpitNotLoggedTopContainer.module.css';

const CockpitNotLoggedTopContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);

  const handleClickDriveLicense = useCallback(
    (category: string) => {
      dispatch(SET_USER_SELECTED_CATEGORY({ category }));
    },
    [dispatch]
  );

  return (
    <RulesGroupItem>
      <PageTitle>{t('CockpitNotLoggedTopContainer.title')}</PageTitle>
      <CategoryList isDemo onClickDriveLicense={handleClickDriveLicense} selectedCategory={selectedCategory} />
      <div className={styles.btnGroup}>
        <Button className={styles.offersButton} variant="primary">
          <a target="_blank" rel="noopener noreferrer" href={t('CockpitNotLoggedTopContainer.dealsButtonLink')}>
            {t('CockpitNotLoggedTopContainer.dealsButton')}
          </a>
        </Button>
        <Button as={LinkWrapper} to={`/questions/${selectedCategory}/demo`} variant="outline-primary">
          {t('CockpitNotLoggedTopContainer.demoquestions')}
        </Button>
        <Button as={LinkWrapper} to="/exam" variant="outline-primary">
          {t('CockpitNotLoggedTopContainer.demoexam')}
        </Button>
      </div>
    </RulesGroupItem>
  );
};

export default CockpitNotLoggedTopContainer;
