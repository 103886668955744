import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './HowItWorksPage.module.css';

const SubtitleText: React.FC<{ text: string; id?: string }> = ({ text, children, id }) => {
  return (
    <Row>
      <Col id={id || ''}>
        <p className={styles.subtitle}>{text}</p>
        {children}
      </Col>
    </Row>
  );
};

const CategoryCardItem: React.FC<{ cardTitle: string; cardText: string; text: string; t: any }> = ({
  cardTitle,
  cardText,
  text,
  t
}) => {
  return (
    <Row className="align-items-center">
      <Col lg={4}>
        <Card className={styles.cardCategoriesItem} border="light">
          <Card.Body>
            <Card.Title className="h2">{cardTitle}</Card.Title>
            <Card.Text>{cardText}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <LinkWrapper className="linkBtn" to="/theory">
              {t('myCockpitCategories.weiter')}
              <i className="icon-arrow" />
            </LinkWrapper>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <p className={styles.howItWoksText}>{text}</p>
      </Col>
    </Row>
  );
};

const HowItWorksPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.howItWorksContainer}>
        <Container>
          <Row>
            <Col lg={6}>
              <PageTitle>{t('howItWorksPage.title')}</PageTitle>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={styles.subtitle}>{t('howItWorksPage.subtitle1')}</p>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={7}>
              <p className={styles.howItWoksText}>{t('howItWorksPage.text1')}</p>
              <p className={styles.howItWoksText}>{t('howItWorksPage.text2')}</p>
              <p className={styles.howItWoksText}>
                <Trans i18nKey="howItWorksPage.text3">
                  desc
                  {/*eslint-disable */}
                  <a href={t('howItWorksPage.text3Link')} rel="noopener noreferrer" target="_blank" />
                  {/* eslint-enable */}
                  desc
                </Trans>
              </p>
            </Col>
          </Row>
          <SubtitleText text={t('howItWorksPage.subtitle2')} />
          <Row className="align-items-center">
            <Col className={styles.buttonWrapper} lg={2}>
              <LinkWrapper className={`btn btn-primary ${styles.disabled}`} to="/vipcode">
                {t('homePage.btn.deals')}
              </LinkWrapper>
            </Col>
            <Col>
              <p className={styles.howItWoksText}>{t('howItWorksPage.text4')}</p>
            </Col>
          </Row>
          <Row className={`align-items-center ${styles.sectionMargin}`}>
            <Col className={styles.buttonWrapper} lg={2}>
              <Button className={styles.loginBtn} variant="secondary">
                {t('app.btn.login')}
              </Button>
            </Col>
            <Col>
              <p className={styles.howItWoksText}>{t('howItWorksPage.text5')}</p>
            </Col>
          </Row>

          <Row>
            <Col className={styles.tcsNumberContainer}>
              <p className={styles.tcsNumberTitle}>{t('howItWorksPage.tcsNumber.title')}</p>
              <ul className={styles.tcsNumberList}>
                <li>{t('howItWorksPage.tcsNumber.text1')}</li>
                <li>{t('howItWorksPage.tcsNumber.text2')}</li>
                <li>
                  <Trans i18nKey="howItWorksPage.tcsNumber.text3">
                    desc
                    {/*eslint-disable */}
                    <a
                      className={styles.phoneLink}
                      href={t('howItWorksPage.tcsNumber.text3Link')}
                      rel="noopener noreferrer"
                    />
                    {/* eslint-enable */}
                    desc
                  </Trans>
                </li>
              </ul>
            </Col>
          </Row>

          <SubtitleText text={t('howItWorksPage.subtitle3')} />

          <CategoryCardItem
            cardTitle={t('myCockpitCategories.title.theory')}
            cardText={t('myCockpitCategories.subtitle.theory')}
            text={t('howItWorksPage.text6')}
            t={t}
          />
          <CategoryCardItem
            cardTitle={t('myCockpitCategories.title.ask')}
            cardText={t('myCockpitCategories.subtitle.ask')}
            text={t('howItWorksPage.text7')}
            t={t}
          />
          <CategoryCardItem
            cardTitle={t('myCockpitCategories.title.exam')}
            cardText={t('myCockpitCategories.subtitle.exam')}
            text={t('howItWorksPage.text8')}
            t={t}
          />
        </Container>
      </div>
    </>
  );
};

export default HowItWorksPage;
