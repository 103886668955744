import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Jumbotron, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { ICockpitTopPanelProps } from './CockpitTopPanel.types';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { CategoryList } from '../CategoryList/CategoryList';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { P3 } from '../../ui-components/P3/P3';
import { getPlanDateCountLabel } from '../../utils/utils';
import useDeviceSizes from '../../hooks/useDeviceSizes';
import { IRootState } from '../../reducer';
import styles from './CockpitTopPanel.module.css';

const CockpitTopPanel: React.FC<ICockpitTopPanelProps> = ({
  userName,
  bestResult,
  onClickDriveLicense,
  selectedCategory,
  expirePlaneDateTime
}: ICockpitTopPanelProps) => {
  const { t } = useTranslation();
  const isAnyExamStarted = Boolean(bestResult && bestResult.questionCount);
  const availablePlanDays = getPlanDateCountLabel(expirePlaneDateTime);
  const userCategories = useSelector(({ user }: IRootState) => user.profile.categories);
  const { width } = useDeviceSizes();
  const isMobileMode = useMemo(() => width <= 991, [width]);

  const renderCategorySection = useMemo(() => {
    return (
      <div className={styles.cardFooter}>
        <CategoryList
          onClickDriveLicense={onClickDriveLicense}
          selectedCategory={selectedCategory}
          userAvailableCategories={userCategories}
          withFilteredCategories
        />
        <Button
          disabled={!userCategories}
          className={classNames({ [styles.buttonDisable]: !userCategories })}
          variant="secondary"
        >
          <LinkWrapper
            className={classNames(styles.learnButtonLinkColor, { [styles.buttonDisable]: !userCategories })}
            to={`/questions/${selectedCategory}/all`}
          >
            {t('cockpitTopPanel.hi.learn')}
          </LinkWrapper>
        </Button>
      </div>
    );
  }, [selectedCategory, onClickDriveLicense, userCategories, t]);

  const renderDurationLeft = useMemo(() => {
    return (
      <div className={styles.mobileJumbotron}>
        <Jumbotron className={styles.jumbotron}>
          <div className={styles.panelTitle}>{t('cockpitTopPanel.duration.title')}</div>
          <div className={styles.daysContent}>
            <div className={styles.daysValue}>{availablePlanDays}</div>
            <div className={styles.daysLabel}>{t('cockpitTopPanel.duration.days')}</div>
          </div>
        </Jumbotron>
      </div>
    );
  }, [availablePlanDays, t]);

  const renderTopSection = useMemo(() => {
    if (isMobileMode) {
      return (
        <>
          {renderCategorySection}
          {renderDurationLeft}
        </>
      );
    }
    return (
      <>
        {renderDurationLeft}
        {renderCategorySection}
      </>
    );
  }, [isMobileMode, renderCategorySection, renderDurationLeft]);

  return (
    <div className={styles.cockpitTopPanel}>
      <Container>
        <Row>
          <Col>
            <div className={styles.jList}>
              <Jumbotron className={styles.jumbotron}>
                <PageTitle>{`${t('cockpitTopPanel.hi.title')}, ${userName}`}</PageTitle>
                <P3>{t('cockpitTopPanel.hi.subtitle1')}</P3>
                <P3>{t('cockpitTopPanel.hi.subtitle2')}</P3>
                {renderTopSection}
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.jumbotronHeader}>
                  <div className={styles.jumbotronHeaderItem}>
                    <div className={styles.panelTitle}>{t('cockpitTopPanel.results.title')}</div>
                  </div>
                  {isAnyExamStarted && (
                    <div className={styles.panelDateList}>
                      <div className={styles.panelDate}>{moment(bestResult.startTime).format('DD.MM.YYYY')}</div>
                      <div className={styles.panelTime}>{moment(bestResult.startTime).format('HH:mm')}</div>
                    </div>
                  )}
                </div>
                {isAnyExamStarted && (
                  <div className={styles.panelCount}>{`${bestResult.truePointCount}/${bestResult.pointCount}`}</div>
                )}
                {!isAnyExamStarted && <P3>{t('cockpitTopPanel.results.placeholder')}</P3>}
                <LinkWrapper to={isAnyExamStarted ? '/exam-history' : '/exam'} className="linkBtn">
                  {isAnyExamStarted ? t('cockpitTopPanel.results.link') : t('cockpitTopPanel.results.startExamLink')}
                  <i className="icon-arrow" />
                </LinkWrapper>
              </Jumbotron>
              <Jumbotron className={styles.jumbotron}>
                <div className={styles.panelTitle}>{t('cockpitTopPanel.duration.title')}</div>
                <div className={styles.daysContent}>
                  <div className={styles.daysValue}>{availablePlanDays}</div>
                  <div className={styles.daysLabel}>{t('cockpitTopPanel.duration.days')}</div>
                </div>
              </Jumbotron>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CockpitTopPanel;
