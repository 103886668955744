/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from 'react-bootstrap';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';
import styles from './AutoTheoriepruefungPage.module.css';

const AutoTheoriepruefungPage = () => {
  return (
    <div className={styles.root}>
      <Container>
        <h1> Auto Theorieprüfung: Effektives Lernen mit »theorie24«</h1>

        <img
          className={styles.mainImage}
          src="/content/tour/fragen.png"
          alt="Auto Theorieprüfung - theorie24-App"
          width="400"
          height="250"
        />
        <p>
          Die <b>Auto Theorieprüfung</b> ist der erste Schritt auf dem Weg zum unbefristeten Führerausweis. Wenn du ein
          Lerntool suchst, mit dem du dich optimal auf die Prüfung vorbereiten kannst, bist du hier am richtigen Ort:
          Die bewährte Lern-App »theorie24« bietet dir jede erdenkliche Unterstützung.{' '}
          <b>Jetzt online testen und preisgünstig bestellen!</b>
        </p>

        <h2>Im ersten Anlauf durch die Auto Theorieprüfung</h2>

        <p>
          Die meisten Fahrschüler bestehen die Auto Theorieprüfung – doch längst nicht jeder meistert diese
          Herausforderung im ersten Anlauf. Je nach Stadt und Kanton fallen 25 oder sogar 30 Prozent der Prüflinge beim
          ersten Versuch durch. Die zweite Prüfung bringt einen <b>erhöhten Lernaufwand und zusätzliche Kosten</b> mit
          sich.
        </p>

        <p>
          Das muss nicht sein! Indem du dich mit »theorie24« auf die Auto-Theorieprüfung vorbereitest, erhöhst du deine
          Chancen, den Test gleich beim ersten Mal zu bestehen. Nachdem du diese Hürde genommen hast, kannst du deine
          Aufmerksamkeit ganz dem praktischen Teil der Prüfung widmen. Zu einem fairen Preis erhältst du eine App, die
          sich <b>kinderleicht bedienen lässt</b> und deinen Lernerfolg in jeder Hinsicht fördert.
        </p>

        <h3>Lernen mit den offiziellen Prüfungsfragen</h3>

        <p>
          Als Nutzer von »theorie24« lernst du mit den <b>offiziellen Prüfungsfragen</b>. Dies stellt einen klaren
          Vorteil dar: Wenn du die Prüfung ablegst, kommen darin viele Fragestellungen vor, mit denen du dich schon
          einmal beschäftigt hast. Die Erfolgsaussichten sind deshalb wesentlich besser, als wenn du ein anderes
          Lerntool verwenden würdest.
        </p>

        <p>
          Unser Unternehmen ist Partner der Vereinigung der Strassenverkehrsämter (asa) – daher haben wir Zugang zu den
          aktuellen Prüfungsfragen. Unsere Lern-App wird <b>laufend aktualisiert</b>, sodass du immer alle
          prüfungsrelevanten Fragen zur Verfügung hast.
        </p>

        <h3>Flexibel zwischen den Geräten wechseln</h3>

        <p>
          Mit »theorie24« kannst du auf <u>allen Geräten</u> für die Auto Theorieprüfung lernen. Zu Hause kannst du dein
          Notebook oder einen Desktop-Computer nutzen, um dich vorzubereiten. Unterwegs greifst du einfach zum
          Smartphone oder nutzt ein Tablet. Die Lern-App ist mit allen Geräten und Systemen kompatibel. Mit ein und
          demselben Login erhältst du überall Zugang zur App. So wird das Lernen noch einfacher!
        </p>

        <h3>Nutze unsere kostenlose Demoversion!</h3>

        <p>
          Auf unserer Webseite kannst du eine kostenfreie
          <LinkWrapper to="/mycockpitdemo"> Demoversion</LinkWrapper> von »theorie24« nutzen und sogar eine{' '}
          <b>Demoprüfung</b> absolvieren. Du kannst dir ausserdem 3D-Animationen ansehen, die knifflige
          Verkehrssituationen veranschaulichen. So kannst du dir ein umfassendes Bild von unserer Lern-App machen. Wenn
          du dich anschliessend dazu entscheidest, »theorie24« für die Vorbereitung auf die Auto Theorieprüfung zu
          nutzen, bestellst du einfach eines der angebotenen <LinkWrapper to="/offers">Pakete</LinkWrapper>.
        </p>

        <img
          className={styles.mainImage}
          src="/content/tour/theorie.png"
          alt="Auto Theorieprüfung Auto – theorie24-App (Basistheorie)"
          width="400"
          height="300"
        />
        <h3>Lernen in 9 Sprachen</h3>

        <p>
          Die Theorie-Lernapp »theorie24« ist nicht nur auf Deutsch, sondern auch in <b>anderen Sprachen</b> verfügbar.
          Das Tool unterstützt 8 weitere Sprachen – darunter Englisch, Türkisch, Portugiesisch und Albanisch. So können
          sich auch Nicht-Muttersprachler gezielt auf die Auto Theorieprüfung vorbereiten und ihren Lernerfolg steigern.
        </p>

        <h3>Jetzt starten!</h3>

        <p>
          <b>
            <LinkWrapper to="/offers">Beginne noch heute</LinkWrapper>
          </b>{' '}
          mit der Vorbereitung auf die theoretische Autoprüfung! Dein Konto ist in wenigen Minuten eingerichtet. Wir
          bieten verschiedene Pakete an, die sich durch die Dauer des Zugangs (maximal 365 Tage) unterscheiden. Die
          Zugänge für 30 und 60 Tage sind übrigens auch <b>per Telefonrechnung bestellbar</b>. VIP-Cards, die sich
          perfekt als Geschenk eignen, findest du in unserem{' '}
          <a href="https://www.fahrschultheorie.ch/de/fahrschuler/theorie24-ch-vip-card-fur-online-portal-alle-kategorien.html">
            Shop
          </a>
          .
        </p>
      </Container>
    </div>
  );
};

export default AutoTheoriepruefungPage;
