import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ITeacher3dNavMenuProps } from './Teacher3dNavMenu.types';
import styles from './Teacher3dNavMenu.module.css';

const teacher3dMainPage = (match, location) => {
  return location.pathname === '/3dteacher' || location.pathname === '/3dteacher/prelude-without-signaling';
};

const Teacher3dNavMenu: React.FC<ITeacher3dNavMenuProps> = ({ currentLanguage }: ITeacher3dNavMenuProps) => {
  const { t } = useTranslation();

  return (
    <Nav className={styles.navList}>
      <Nav.Item>
        <Nav.Link
          as={NavLink}
          isActive={teacher3dMainPage}
          to={`/${currentLanguage}/3dteacher/prelude-without-signaling`}
        >
          {t('teacher3dNavMenu.preludeWithoutSignaling')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={`/${currentLanguage}/3dteacher/presence-with-signaling`}>
          {t('teacher3dNavMenu.presenceWithSignaling')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to={`/${currentLanguage}/3dteacher/light-signal`}>
          {t('teacher3dNavMenu.lightSignal')}
          <i className="icon-arrow" />
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default Teacher3dNavMenu;
