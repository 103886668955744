import { handleActions } from 'redux-actions';
import { LoginState } from './login.types';
import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_MODAL_OPEN,
  LOGIN_MODAL_HIDE,
  LOGOUT_MODAL_OPEN,
  LOGOUT_MODAL_HIDE,
  GOOD_BYE_MODAL_CLOSE,
  SET_IS_LOGGED,
  SET_PASSWORD,
  LOGIN_WITH_TOKEN_REQUEST,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGIN_WITH_TOKEN_FAILURE,
  RECOVERY_PASSWORD_MODAL_OPEN,
  RECOVERY_PASSWORD_MODAL_HIDE,
  RECOVERY_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_FAILURE,
  RECOVERY_PASSWORD_SUCCESS
} from '../actions/login.action';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { DEVICE_UID } from '../constants/storageKeys';
import { defaultRequestStatus } from './fetch.types';
import { isSSR } from '../utils/utils';

const defaultState: LoginState = {
  isLogged: isSSR() ? false : !!localStorage.getItem(DEVICE_UID),
  isLoginModalOpen: false,
  isRecoveryPasswordModalOpen: false,
  isLogoutModalOpen: false,
  isGoodByeModalOpen: false,
  token: '',
  password: null,
  request: defaultRequestStatus,
  passwordRecoveryRequest: defaultRequestStatus,
  tokenRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [LOGIN_REQUEST]: () => ({
      ...defaultState,
      isLogged: !!localStorage.getItem(DEVICE_UID),
      isLoginModalOpen: true,
      request: { status: PENDING, error: null }
    }),
    [LOGIN_FAILURE]: (state, action) => ({
      ...defaultState,
      isLogged: !!localStorage.getItem(DEVICE_UID),
      isLoginModalOpen: true,
      request: { status: FAILURE, error: action.payload }
    }),
    [LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      request: { status: SUCCESS, error: null },
      isLogged: true,
      isLoginModalOpen: false,
      token: action.payload
    }),
    [RECOVERY_PASSWORD_REQUEST]: state => ({
      ...state,
      passwordRecoveryRequest: { status: PENDING, error: null }
    }),
    [RECOVERY_PASSWORD_SUCCESS]: state => ({
      ...state,
      passwordRecoveryRequest: { status: SUCCESS, error: null }
    }),
    [RECOVERY_PASSWORD_FAILURE]: (state, action) => ({
      ...state,
      passwordRecoveryRequest: { status: FAILURE, error: action.password }
    }),
    [LOGOUT_REQUEST]: state => ({
      ...state,
      isLogoutModalOpen: true,
      request: { status: PENDING, error: null }
    }),
    [LOGOUT_FAILURE]: (state, action) => ({
      ...state,
      isLogoutModalOpen: true,
      request: { status: FAILURE, error: action.payload }
    }),
    [LOGOUT_SUCCESS]: () => ({
      ...defaultState,
      isLogoutModalOpen: false,
      isGoodByeModalOpen: true,
      request: { status: SUCCESS, error: null },
      isLogged: false
    }),
    [LOGIN_MODAL_OPEN]: state => ({
      ...state,
      isLoginModalOpen: true
    }),
    [LOGIN_MODAL_HIDE]: state => ({
      ...state,
      isLoginModalOpen: false
    }),
    [RECOVERY_PASSWORD_MODAL_OPEN]: state => ({
      ...state,
      isRecoveryPasswordModalOpen: true
    }),
    [RECOVERY_PASSWORD_MODAL_HIDE]: state => ({
      ...state,
      isRecoveryPasswordModalOpen: false
    }),
    [LOGOUT_MODAL_OPEN]: state => ({
      ...state,
      isLogoutModalOpen: true
    }),
    [LOGOUT_MODAL_HIDE]: state => ({
      ...state,
      isLogoutModalOpen: false
    }),
    [GOOD_BYE_MODAL_CLOSE]: () => ({
      ...defaultState,
      isLogged: !!localStorage.getItem(DEVICE_UID)
    }),
    [SET_IS_LOGGED]: (state, action) => ({
      ...state,
      isLogged: action.payload.isLogged
    }),
    [SET_PASSWORD]: (state, action) => ({
      ...state,
      password: action.payload
    }),
    [LOGIN_WITH_TOKEN_REQUEST]: state => ({
      ...state,
      tokenRequest: { status: PENDING, error: null }
    }),
    [LOGIN_WITH_TOKEN_SUCCESS]: state => ({
      ...state,
      tokenRequest: { status: SUCCESS, error: null }
    }),
    [LOGIN_WITH_TOKEN_FAILURE]: (state, action) => ({
      ...state,
      tokenRequest: { status: FAILURE, error: action.payload }
    })
  },
  defaultState
);

export default reduces;
