import { IVerifyMemberRequestPayload } from './verifyMember.types';
import fetch from './fetch';

export const verifyMemberTCS = (payload: IVerifyMemberRequestPayload) => {
  return fetch('PUT', '/user/verifymember', payload);
};

export const remainingMemberTCS = () => {
  return fetch('GET', '/user/remaining');
};
