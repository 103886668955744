import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ITheoryPageProps } from './TheoryGlossaryPage.types';
import TheoryBookContainer from '../../containers/TheoryBookContainer';
import TheoryGlossaryContainer from '../../containers/TheoryGlossaryContainer';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { THEORY_PAGE_ANCHOR } from '../../constants/anchors';
import InstructorContainer from '../../containers/InstructorContainer/InstructorContainer';
import styles from './TheoryGlossaryPage.module.css';

const TheoryGlossaryPage: React.FC<ITheoryPageProps> = ({ match }: ITheoryPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.glossaryPage} id={THEORY_PAGE_ANCHOR}>
      <Container>
        <Row>
          <Col>
            <LinkWrapper className={styles.linkBtn} to="/theory">
              <i className="icon-arrow" />
              {t('theoryPage.linkBack')}
            </LinkWrapper>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="h2">{t('theoryPage.glossary.title')}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.glossaryPageContent}>
              <TheoryGlossaryContainer />
              <TheoryBookContainer pathName="glossary" page={match.params.page} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className={styles.mobileView}>
        <Row>
          <Col>
            <InstructorContainer />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TheoryGlossaryPage;
