import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Container, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import SelectGameModeContainer from '../../containers/SelectGameModeContainer/SelectGameModeContainer';
import GameDetailsContainer from '../../containers/GameDetailsContainer';
import styles from './GameModePage.module.css';

const GameModePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.questionPage}>
        <Container>
          <Row>
            <Col>
              <SelectGameModeContainer />
            </Col>
          </Row>
        </Container>
        <a
          href="mailto:info@theorie24.ch?subject=Game%20Mode%20theorie24&body="
          rel="noopener noreferrer"
          target="_blank"
          className={styles.provideFeedBackButton}
        >
          {t('gameContainer.provideFeedbackNow')}
          <motion.div animate={{ x: [0, -3, 0, 3, 0] }} transition={{ duration: 0.5, repeat: Infinity }}>
            &gt;
          </motion.div>
        </a>
        <div className={styles.gameDetails}>
          <Container>
            <Row>
              <Col>
                <GameDetailsContainer />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default GameModePage;
