import { handleActions } from 'redux-actions';
import { IVoucherState } from './voucher.types';
import { defaultRequestStatus } from './fetch.types';
import {
  VERIFY_VOUCHER_CODE_FAILURE,
  VERIFY_VOUCHER_CODE_REQUEST,
  VERIFY_VOUCHER_CODE_SUCCESS
} from '../actions/voucher.actions';
import { FAILURE, PENDING, SUCCESS } from '../constants/store.constants';

const defaultVoucherState = {
  code: '',
  offerId: '',
  teacherId: null,
  affiliatesId: null
};

const defaultState: IVoucherState = {
  verifyCodeRequest: defaultRequestStatus,
  voucher: defaultVoucherState
};

const reduces = handleActions(
  {
    [VERIFY_VOUCHER_CODE_REQUEST]: state => ({
      ...state,
      verifyCodeRequest: { status: PENDING, error: null }
    }),
    [VERIFY_VOUCHER_CODE_SUCCESS]: (state, action) => ({
      ...state,
      verifyCodeRequest: { status: SUCCESS, error: null },
      voucher: action.payload
    }),
    [VERIFY_VOUCHER_CODE_FAILURE]: (state, action) => ({
      ...state,
      verifyCodeRequest: { status: FAILURE, error: action.payload }
    })
  },
  defaultState
);

export default reduces;
