import alasql from 'alasql';
import { db } from '../config/firebase.config';

export const getExamById = async (userId: string, examId: string) => {
  if (userId) {
    const snapshot = await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(examId)
      .get();

    return snapshot.data();
  }

  const exams = await alasql('SELECT * FROM exam WHERE examId = ?', [examId]);

  return exams[0] ? exams[0] : null;
};

export const fbrecoveryExamAnswers = async (userId: string, examId: string) => {
  const examAnswerList = {};

  if (userId) {
    const examAnswersFromDb = await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(examId)
      .collection('answer')
      .get();
    if (examAnswersFromDb) {
      examAnswersFromDb.docs.forEach(answer => {
        examAnswerList[examId] = answer.data();
      });
      return examAnswerList;
    }
  } else {
    const answers = await alasql('SELECT * FROM examanswer WHERE examId = ?', [examId]);
    examAnswerList[examId] = {};
    answers.forEach(answer => {
      examAnswerList[examId][answer.qNr] = answer;
    });
    return examAnswerList;
  }

  return null;
};

export const updateAnswerPoint = async (userId, payload) => {
  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(payload.examId)
      .update({
        trueQuestionCount: payload.trueQuestionCount,
        truePointCount: payload.truePointCount
      });
  } else {
    await alasql('UPDATE exam SET trueQuestionCount = ?, truePointCount = ? WHERE examId = ?', [
      payload.trueQuestionCount,
      payload.truePointCount,
      payload.examId
    ]);
  }
};

export const createExamData = async (userId, examId, data) => {
  const newData = { ...data, examId };
  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(examId)
      .set(newData);
  } else {
    const res = alasql('SELECT * FROM exam WHERE examId = ?', [examId]);
    if (res.length) {
      await alasql('UPDATE exam SET ? WHERE uid = ?', [data, res[0].uid]);
    } else {
      await alasql('SELECT * INTO exam FROM ?', [[newData]]);
    }
  }
};

export const addExamAnswer = async (userId, examId, questionId, answer) => {
  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(examId)
      .collection('answer')
      .doc('answerList')
      .update({
        [questionId]: answer
      });
  } else {
    const res = await alasql('SELECT * FROM examanswer WHERE examId = ? AND qNr = ?', [examId, questionId.toString()]);
    if (res.length) {
      const columns: string[] = [];
      const values: string[] = [];
      const columnKeys = Object.keys(answer);

      columnKeys.forEach(column => {
        columns.push(`${column.toString()} = ?`);
        values.push(answer[column]);
      });

      values.push(res[0].uid);
      await alasql(`UPDATE examanswer SET ${columns.join(', ')} WHERE uid = ?`, values);
    } else {
      await alasql('SELECT * INTO examanswer FROM ?', [
        [
          {
            ...answer,
            qNr: questionId.toString(),
            examId: examId.toString()
          }
        ]
      ]);
    }
  }
};

export const createExamAnswer = async (userId, examId, answerMap) => {
  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(examId)
      .collection('answer')
      .doc('answerList')
      .set(answerMap);
  } else {
    const answerMapKeys = Object.keys(answerMap);
    answerMapKeys.forEach(qNr => {
      addExamAnswer(userId, examId, qNr, answerMap[qNr]);
    });
  }
};

export const finishExam = async (userId, examId) => {
  const exam = await getExamById(userId, examId);
  if (exam && exam.finish) {
    // exam already finished => don't update endTime again!
    return;
  }

  if (userId) {
    await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .doc(examId)
      .update({
        finish: true,
        endTime: +new Date()
      });
  } else {
    await alasql('UPDATE exam SET finish = ?, endTime = ? WHERE examId = ?', [true, +new Date(), examId]);
  }
};

export const lastExam = async userId => {
  if (userId) {
    const snapshot = await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .orderBy('startTime', 'desc')
      .limit(1)
      .get();

    return snapshot.docs[0] ? snapshot.docs[0].data() : null;
  }
  const exams = await alasql('SELECT * FROM exam ORDER BY startTime DESC LIMIT 1');

  return exams[0] ? exams[0] : null;
};

export const getLastExams = async (userId, categoryForQuery) => {
  if (userId) {
    const lastExams = await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .where('category', '==', categoryForQuery)
      .orderBy('startTime', 'desc')
      .limit(50)
      .get();

    return lastExams.docs.map(item => ({
      data: item.data(),
      id: item.id
    }));
  }
  const exams = await alasql('SELECT * FROM exam WHERE category = ? ORDER BY startTime DESC LIMIT 4', [
    categoryForQuery
  ]);

  return exams.map(item => ({
    data: item,
    id: item.examId
  }));
};

export const getAllExams = async (userId, categoryForQuery) => {
  if (userId) {
    const allExams = await db
      .collection('user')
      .doc(userId)
      .collection('exam')
      .where('category', '==', categoryForQuery)
      .orderBy('startTime', 'desc')
      .get();

    const info = allExams.docs.sort(
      (a, b) =>
        a.data().questionCount / a.data().trueQuestionCount - b.data().questionCount / b.data().trueQuestionCount
    );

    return info[0] ? info[0].data() : null;
  }
  const exams = await alasql('SELECT * FROM exam WHERE category = ? ORDER BY startTime DESC', [categoryForQuery]);

  const info = exams.sort((a, b) => a.questionCount / a.trueQuestionCount - b.questionCount / b.trueQuestionCount);

  return info[0] ? info[0] : null;
};
