import React, { useCallback } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TermListItemProps } from './TermListItem.types';

const TermListItem: React.FC<TermListItemProps> = ({ onSetEventKey, eventKey, name }: TermListItemProps) => {
  const { t } = useTranslation();

  const handleChangeIndex = useCallback(() => {
    onSetEventKey(eventKey);
  }, [onSetEventKey, eventKey]);

  return <ListGroup.Item onClick={handleChangeIndex}>{t(name)}</ListGroup.Item>;
};

export default TermListItem;
