import {
  IGetTheoryByKey,
  ChaptersAttributesSchema,
  ITheory,
  TChapters,
  TPages,
  PagesAttributesSchema,
  TRegisters,
  RegistersAttributesSchema
} from './theory.schema';

import pageDe from '../db/page_de.json';
import pageFr from '../db/page_fr.json';
import pageIt from '../db/page_it.json';
import pageEn from '../db/page_en.json';

import chapterDe from '../db/chapter_de.json';
import chapterFr from '../db/chapter_fr.json';
import chapterIt from '../db/chapter_it.json';
import chapterEn from '../db/chapter_en.json';

import registerDe from '../db/register_de.json';
import registerFr from '../db/register_fr.json';
import registerIt from '../db/register_it.json';
import registerEn from '../db/register_en.json';

const chapterJson = {
  de: chapterDe,
  fr: chapterFr,
  it: chapterIt,
  en: chapterEn
};

const pageJson = {
  de: pageDe,
  fr: pageFr,
  it: pageIt,
  en: pageEn
};

const registerJson = {
  de: registerDe,
  fr: registerFr,
  it: registerIt,
  en: registerEn
};

export const getTheory = async ({ language }: IGetTheoryByKey) => {
  const theory: ITheory = {
    chapters: [],
    pages: [],
    registers: []
  };

  await Promise.all([
    Promise.all(
      chapterJson[language].map(async item => {
        const data = item as TChapters;
        if (await ChaptersAttributesSchema.isValid(data)) {
          theory.chapters.push(data);
        }
      })
    ),
    Promise.all(
      pageJson[language].map(async item => {
        const data = item as TPages;
        if (await PagesAttributesSchema.isValid(data)) {
          theory.pages.push(data);
        }
      })
    ),
    Promise.all(
      registerJson[language].map(async item => {
        const data = item as TRegisters;
        if (await RegistersAttributesSchema.isValid(data)) {
          theory.registers.push(data);
        }
      })
    )
  ]);

  return { theory, language };
};
