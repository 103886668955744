import React from 'react';
import { useTranslation } from 'react-i18next';
import { WHATS_APP_LINK } from '../../constants';
import styles from './SocialLinkList.module.css';

const SocialLinkList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.socialLinkListMobile}>
      <ul className={styles.socialLinkList}>
        <li>
          <a className={styles.socialLinkItem} rel="noopener noreferrer" href={t('info.telhref')}>
            <i className="icon-support" />
          </a>
          <span className={styles.socialLinkItemText}>{t('homePage.social.call')}</span>
        </li>
        <li>
          <a className={styles.socialLinkItem} rel="noopener noreferrer" target="_blank" href={WHATS_APP_LINK}>
            <i className="icon-whatsapp" />
          </a>
          <span className={styles.socialLinkItemText}>{t('homePage.social.whatsapp')}</span>
        </li>
        <li>
          <a className={styles.socialLinkItem} rel="noopener noreferrer" target="_blank" href={t('info.emailhref')}>
            <i className="icon-email" />
          </a>
          <span className={styles.socialLinkItemText}>{t('homePage.social.email')}</span>
        </li>
      </ul>
    </div>
  );
};

export default SocialLinkList;
