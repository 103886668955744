/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './TheoriepruefungFuehrerscheinPage.module.css';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';

const TheoriepruefungFuehrerscheinPage = () => {
  return (
    <div className={styles.root}>
      <Container>
        <h1> Theorieprüfung für den Führerschein: Jetzt lernen mit theorie24.ch</h1>

        <img
          src="/content/tour/fragen.png"
          alt="Theorieprüfung Führerschein - theorie24-App"
          width="400"
          height="250"
        />
        <p>
          Jeder Fahrschüler in der Schweiz weiss: Ohne Theorieprüfung kein Führerschein. Wenn du eine App suchst, die
          dir das Lernen erleichtert, bist du hier richtig: Mit »theorie24» hebst du deine Prüfungsvorbereitung auf eine
          neue Stufe. <b>Jetzt registrieren und sofort mit dem Lernen beginnen!</b>
        </p>

        <h3>Bis zu 365 Tage online lernen</h3>

        <p>
          Falls du die Theorieprüfung für den Führerschein erst in einigen Monaten machst, kommen für dich die Pakete
          „Optimum“ und „Optimum+“ in Frage. Du erhältst einen Zugang für 60 bzw. 90 Tage. Du kannst die Lern-App
          unbegrenzt nutzen und zahlst dafür nur <b>CHF 29 bis CHF 39</b> – je nachdem, für welches Paket du dich
          entscheidest.
        </p>

        <p>
          Eine günstige Alternative ist das Paket „Fast Track“. Wenn du ein Schnelllerner bist und dir zutraust, die
          Theorieprüfung für den Führerschein in Rekordzeit hinter dich zu bringen, ist dieses Paket eine gute Wahl. Das
          1-Monats-Angebot kostet nur <b>CHF 19</b>. Effektive Prüfungsvorbereitung zum Taschengeldpreis!
        </p>

        <p>
          Das Paket „Safe“ ist das richtige, wenn du ohne Zeitdruck für die Theorieprüfung lernen willst. Du kannst für
          ein ganzes Jahr auf unsere Lern-App zugreifen. Kein Stress, kein Lerndruck – du bereitest dich in deinem
          eigenen Tempo vor und kannst dir genügend Zeit für alle Aspekte des Theoriestoffs nehmen.
        </p>

        <h3>Effektive Vorbereitung mit den Original-Prüfungsfragen</h3>

        <p>
          Mit »theorie24» bereitest du dich optimal auf die Theorieprüfung für den Führerschein vor. Als offizieller
          Lizenzpartner der Vereinigung der Strassenverkehrsämter (asa) dürfen wir die Original-Prüfungsfragen in unsere
          Lern-App integrieren. Natürlich werden die Fragen <b>ständig an die aktuellen Anforderungen angepasst</b> –
          diesbezüglich sind wir immer à jour.
        </p>

        <h3>Ein Login für alles</h3>

        <p>
          Als Nutzer von »theorie24« kannst du mit einem Login alle Geräte nutzen. Die App läuft problemlos auf
          stationären und portablen Endgeräten – ob Smartphone, Tablet, Notebook oder Deskop-Computer. Das Lernen von
          unterwegs hat viele Vorteile: Du kannst Leerlaufphasen zur Prüfungsvorbereitung nutzen und{' '}
          <b>lernen, wo und wann du willst</b>.
        </p>

        <h3>Alle Fahrzeugklassen in einer App</h3>

        <p>
          »theorie24« vereint <b>alle Fahrzeugkategorien in einer App</b> – Auto, Motorrad, Mofa und sogar
          landwirtschaftliche Nutzfahrzeuge. Es spielt daher keine Rolle, ob die Theorieprüfung für deinen Führerschein
          in der Klasse A, B, F oder G stattfindet. Du kannst die App auch mehrfach nutzen – zum Beispiel, wenn du erst
          den Autoführerschein und dann den Führerausweis für das Motorrad machst.
        </p>

        <h3>Jetzt einen Zugang einrichten!</h3>

        <p>
          Richte noch heute einen Zugang ein! Je früher du damit beginnst, dich auf die Theorieprüfung für deinen
          Führerschein vorzubereiten, desto höher sind deine Erfolgschancen. Mit »theorie24« haben schon viele
          Fahrschüler die Theorieprüfung im ersten Anlauf geschafft.{' '}
          <b>
            <LinkWrapper to="/offers">Starte jetzt durch</LinkWrapper>
          </b>{' '}
          und hole dir die beste Lern-App auf dem Markt!
        </p>
      </Container>
    </div>
  );
};

export default TheoriepruefungFuehrerscheinPage;
