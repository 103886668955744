import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IGoodByeModalProps } from './GoodByeModal.types';
import Image from '../../ui-components/Image/Image';
import bye1 from '../../img/gifs/bye1.gif';
import bye2 from '../../img/gifs/bye2.gif';
import bye3 from '../../img/gifs/bye3.gif';
import bye4 from '../../img/gifs/bye4.gif';
import bye5 from '../../img/gifs/bye5.gif';
import styles from './GoodByeModal.module.css';

const gifs = [bye1, bye2, bye3, bye4, bye5];

const GoodByeModal: React.FC<IGoodByeModalProps> = ({ show, onHide }: IGoodByeModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal className={styles.modalGoodBye} show={show} onHide={onHide} size="sm">
      <Modal.Body>
        <Image src={useMemo(() => gifs[Math.floor(Math.random() * gifs.length)], [])} width="100%" />
      </Modal.Body>
      <Modal.Footer>
        <h4 className={styles.modalFooterText}>{t('goodBye')}</h4>
      </Modal.Footer>
    </Modal>
  );
};

export default GoodByeModal;
