import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SchoolTableContainer from '../../containers/SchoolTableContainer';
import SchoolSearchContainer from '../../containers/SchoolSearchContainer';
import SchoolMarkersContainer from '../../containers/SchoolMarkersContainer';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { P3 } from '../../ui-components/P3/P3';
import styles from './DrivingSchoolPage.module.css';

const DrivingSchoolPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.schoolPage}>
      <Container>
        <Row>
          <Col>
            <div className={styles.schoolTopContainer}>
              <PageTitle>{t('drivingSchoolPage.title')}</PageTitle>
              <P3>{t('drivingSchoolPage.subtitle')}</P3>
              <SchoolSearchContainer />
            </div>
          </Col>
        </Row>
      </Container>
      <SchoolMarkersContainer />
      <SchoolTableContainer />
    </div>
  );
};

export default DrivingSchoolPage;
