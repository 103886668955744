import { call, put, takeLatest } from 'redux-saga/effects';
import { REGISTER_REQUEST, REGISTER_FAILURE, REGISTER_SUCCESS } from '../actions/register.action';
import { register } from './register.api';

function* signUp({ payload }) {
  try {
    const response = yield call(register, payload);

    if (response.status !== 200) {
      yield put(REGISTER_FAILURE(response));
    } else {
      yield put(REGISTER_SUCCESS());
      window.location.href = response.payload.payment.html || '/';
    }
  } catch (e) {
    yield put(REGISTER_FAILURE(e));
  }
}

function* registerSaga() {
  yield takeLatest(REGISTER_REQUEST, signUp);
}

export default registerSaga;
