import { createAction } from 'redux-actions';

export const LOGIN_REQUEST = createAction('LOGIN_REQUEST');
export const LOGIN_FAILURE = createAction('LOGIN_FAILURE');
export const LOGIN_SUCCESS = createAction('LOGIN_SUCCESS');

export const RECOVERY_PASSWORD_REQUEST = createAction('RECOVERY_PASSWORD_REQUEST');
export const RECOVERY_PASSWORD_FAILURE = createAction('RECOVERY_PASSWORD_FAILURE');
export const RECOVERY_PASSWORD_SUCCESS = createAction('RECOVERY_PASSWORD_SUCCESS');

export const LOGOUT_REQUEST = createAction('LOGOUT_REQUEST');
export const LOGOUT_FAILURE = createAction('LOGOUT_FAILURE');
export const LOGOUT_SUCCESS = createAction('LOGOUT_SUCCESS');

export const LOGIN_WITH_TOKEN_REQUEST = createAction('LOGIN_WITH_TOKEN_REQUEST');
export const LOGIN_WITH_TOKEN_FAILURE = createAction('LOGIN_WITH_TOKEN_FAILURE');
export const LOGIN_WITH_TOKEN_SUCCESS = createAction('LOGIN_WITH_TOKEN_SUCCESS');

export const LOGIN_MODAL_OPEN = createAction('LOGIN_MODAL_OPEN');
export const LOGIN_MODAL_HIDE = createAction('LOGIN_MODAL_HIDE');

export const RECOVERY_PASSWORD_MODAL_OPEN = createAction('RECOVERY_PASSWORD_MODAL_OPEN');
export const RECOVERY_PASSWORD_MODAL_HIDE = createAction('RECOVERY_PASSWORD_MODAL_HIDE');

export const LOGOUT_MODAL_OPEN = createAction('LOGOUT_MODAL_OPEN');
export const LOGOUT_MODAL_HIDE = createAction('LOGOUT_MODAL_HIDE');

export const GOOD_BYE_MODAL_CLOSE = createAction('GOOD_BYE_MODAL_CLOSE');

export const SET_IS_LOGGED = createAction('SET_IS_LOGGED');
export const SET_PASSWORD = createAction('SET_PASSWORD');
