import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Form, Nav } from 'react-bootstrap';
import Cookies from 'js-cookie';
import useReactRouter from 'use-react-router';
import { triggerMenuCollapse } from '../../utils/utils';
import NavLinkWrapper from '../../ui-components/NavLinkWrapper/NavLinkWrapper';
import { IHeaderMenuProps } from './HeaderMenu.types';
import DemoFlag from '../DemoFlag/DemoFlag';
import { lngList } from '../../locales/locale';
import Translate from '../Translate/Translate';
import ThemToggle from '../ThemeToggle/ThemToggle';
import { THEME_COLOR } from '../../constants/storageKeys';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';
import styles from './HeaderMenu.module.css';

const HeaderMenu: React.FC<IHeaderMenuProps> = ({
  isLogged,
  routeProps,
  onLoginClick,
  onLogoutClick,
  isDemo
}: IHeaderMenuProps) => {
  const { location } = useReactRouter();
  const { t } = useTranslation();

  const isGamePath = location.pathname.includes('game');

  const { selectedColorTheme, setThemColor } = useGetSelectedColorTheme();

  const handleLoginBtnClick = useCallback(() => {
    onLoginClick();
    triggerMenuCollapse();
  }, [onLoginClick]);

  const handleLogoutBtnClick = useCallback(() => {
    onLogoutClick();
    triggerMenuCollapse();
  }, [onLogoutClick]);

  // add selected them to cookies
  useEffect(() => {
    Cookies.set(THEME_COLOR, selectedColorTheme);
  }, [selectedColorTheme]);

  const desktopThemeSliderClasses = cx(styles.themSwiperContainerDesktop, { [styles.isGamePath]: isGamePath });
  const mobileThemeSliderClasses = cx(styles.themSwiperContainerMobile, { [styles.isGamePath]: isGamePath });

  return (
    <>
      <Nav className={`${styles.headerMenu} mr-auto`}>
        <Nav.Link as={NavLinkWrapper} to="/questions" href="#questions" hidden={!isLogged} active={false}>
          {t('header.ask')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/exam" href="#exam" hidden={!isLogged} active={false}>
          {t('header.exam')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/theory" href="#theory" hidden={!isLogged} active={false}>
          {t('header.theory')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/game-mode" href="#game-mode" hidden={!isLogged} active={false}>
          {t('header.gameMode')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/3dteacher" href="#3dteacher" hidden={!isLogged} active={false}>
          {t('header.3d')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/ebike" href="#ebike" hidden={!isLogged} active={false}>
          {t('header.ebike')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/contacts" href="#contacts" hidden={!isLogged} active={false}>
          {t('header.contact')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/faq" href="#faq" hidden={!isLogged} active={false}>
          {t('header.faq')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/anleitung" href="#" hidden={!isLogged} active={false}>
          {t('header.manual')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/howitworks" href="#offers" hidden={isLogged} active={false}>
          {t('header.howItWorks')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/register" href="#registration" hidden={isLogged} active={false}>
          {t('header.registration')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/about" href="#about" hidden={isLogged} active={false}>
          {t('header.about')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/faq" href="#faq" hidden={isLogged} active={false}>
          {t('header.faq')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/tcsoffers" href="#offers" hidden={isLogged} active={false}>
          {t('header.deals')}
        </Nav.Link>
        <Nav.Link as={NavLinkWrapper} to="/contacts" href="#contacts" hidden={isLogged} active={false}>
          {t('header.contact')}
        </Nav.Link>
        {/* <Nav.Link as={NavLinkWrapper} to="/instructor" href="#fahrlehrer" hidden={isLogged} active={false}> */}
        {/*  {t('header.drivingInstructor')} */}
        {/* </Nav.Link> */}
        <Nav.Link
          as={NavLinkWrapper}
          to="/mycockpitdemo"
          href="#mycockpitdemo"
          hidden={isLogged || isDemo}
          active={false}
        >
          {t('header.demo')}
        </Nav.Link>
        <DemoFlag isHidden={!isDemo} />
        {isLogged && (
          <Nav.Link
            className={cx(styles.loginBtnItem, styles.loginBtnMobile)}
            as={NavLinkWrapper}
            href="#mycockpit"
            to="/mycockpit"
            active={false}
          >
            {t('header.cockpit')}
          </Nav.Link>
        )}
      </Nav>
      <ThemToggle
        containerClassName={desktopThemeSliderClasses}
        currentThemeName={selectedColorTheme}
        setThemColor={setThemColor}
      />
      <Nav className={styles.loginBtnGroup} hidden={!isLogged}>
        <Nav.Link
          className={cx(styles.loginBtnItem, styles.desktop)}
          as={NavLinkWrapper}
          href="#mycockpit"
          to="/mycockpit"
          active={false}
        >
          {t('header.cockpit')}
        </Nav.Link>
        <Form>
          <Button className={styles.logoutBtn} variant="secondary" onClick={handleLogoutBtnClick}>
            {t('app.btn.logout')}
          </Button>
        </Form>
        <Form>
          <Button className={styles.logoutBtnMobile} variant="link" onClick={handleLogoutBtnClick}>
            {t('app.btn.logout')}
          </Button>
        </Form>
      </Nav>
      <Nav hidden={isLogged}>
        <Form>
          <Button className={styles.loginBtn} variant="secondary" onClick={handleLoginBtnClick}>
            {t('app.btn.login')}
          </Button>
        </Form>
      </Nav>
      <ThemToggle
        containerClassName={mobileThemeSliderClasses}
        currentThemeName={selectedColorTheme}
        setThemColor={setThemColor}
      />
      {/* <Nav hidden={isLogged}> */}
      {/*  <Form> */}
      {/*    <Button className={styles.loginBtnMobile} variant="link" onClick={handleLoginBtnClick}> */}
      {/*      {t('app.btn.login')} */}
      {/*    </Button> */}
      {/*  </Form> */}
      {/* </Nav> */}
      <Nav className={styles.langIconGroup}>
        {Object.keys(lngList).map((lng: string) => {
          return (
            <Nav.Link key={lng} href="#">
              <Translate lng={lngList[lng].shortName} icon={lngList[lng].icon} routeProps={routeProps} />
            </Nav.Link>
          );
        })}
      </Nav>
      <Nav hidden={isLogged}>
        <Form>
          <Button className={styles.loginBtnMobile} variant="link" onClick={handleLoginBtnClick}>
            {t('app.btn.login')}
          </Button>
        </Form>
      </Nav>
      <Nav className={styles.langIconGroup}>
        {Object.keys(lngList).map((lng: string) => {
          return (
            <Nav.Link key={lng} href="#">
              <Translate lng={lngList[lng].shortName} icon={lngList[lng].icon} routeProps={routeProps} />
            </Nav.Link>
          );
        })}
      </Nav>
    </>
  );
};

export default HeaderMenu;
