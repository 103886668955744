import React, { useEffect, useCallback, useState, useRef, RefObject } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { FAILURE, PENDING, SUCCESS, UNCALLED } from '../../constants/store.constants';
import { GET_PAYMENT_METHODS_REQUEST } from '../../actions/paymentMethod.action';
import { GET_OFFERS_REQUEST } from '../../actions/offer.action';
import { REGISTER_RESET_ERROR, REGISTER_CHECK_EMAIL_RESET, REGISTER_REQUEST } from '../../actions/register.action';
import { Loader } from '../../ui-components/Loader/Loader';
import { getErrorMessagesFromResponse } from '../../utils/fetch';
import { selectRegister } from '../../reducer/register.selectors';
import { IRegisterState } from '../../reducer/register.types';
import { IOffersState, Offer } from '../../reducer/offer.types';
import { selectVoucher } from '../../reducer/voucher.selectors';
import { IVoucherState } from '../../reducer/voucher.types';
import { selectSchool } from '../../reducer/scool.selectors';
import { SchoolState } from '../../reducer/school.types';
import { TEACHER_ID, SELECTED_OFFER_KEY, PREV_VOUCHER_CODE } from '../../constants/storageKeys';
import { GET_SCHOOL_BY_ID_REQUEST } from '../../actions/school.action';
import { createSelectOfferById, selectOffer } from '../../reducer/offer.selectors';
import { LoginState } from '../../reducer/login.types';
import { selectLogin } from '../../reducer/login.selectors';
import { IUserState } from '../../reducer/user.types';
import { selectUser } from '../../reducer/user.selectors';
import { IRegisterContainerProps, IRegisterContainerQueryParams } from './RegisterContainerTCS.types';
import { LOGIN_MODAL_OPEN } from '../../actions/login.action';
import { RegisterEmailError } from '../../components/RegisterEmailError';
import { isSSR } from '../../utils/utils';
import { VERIFY_VOUCHER_CODE_REQUEST } from '../../actions/voucher.actions';
import RegisterFormTCS from '../../components/RegisterFormTCS/RegisterFormTCS';
import { IRegisterFormData } from '../../components/RegisterFormTCS/RegisterFormTCS.types';
import { InitRegistrationRequestPayload } from '../../components/RegisterFormTCS/RegisterFormTCS.constants';
import {
  REMAINING_MEMBER_REQUEST,
  VERIFY_MEMBER_ERROR_RESET,
  VERIFY_MEMBER_REQUEST
} from '../../actions/verifyMember.action';
import { RegisterVerificationCodeError } from '../../components/RegisterVerificationCodeError';
import { IRootState } from '../../reducer';
import { IRegisterRequestPayload } from '../../saga/register.types';
import styles from './RegisterContainerTCS.module.css';

const composeRegisterPayload = (
  formData: IRegisterFormData,
  planId: number,
  type: string | null,
  subtype: string | null,
  voucherCode: string | null,
  teacherId: number | null
): IRegisterRequestPayload => ({
  ...formData,
  planId,
  type,
  subtype,
  voucherCode,
  teacherId
});

const composeVerifyCodePayload = (formData: IRegisterFormData) => {
  return {
    birthDate: formData.birthDate,
    email: formData.email,
    firstName: formData.firstname,
    lastName: formData.lastname,
    tcsMemberId: formData.tcsNumber
  };
};

const OFFER_ID_LIST = {
  categoryAB: 11,
  categoryFGM: 12
};

const RegisterContainerTCS: React.FC<IRegisterContainerProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLogged }: LoginState = useSelector(selectLogin);
  const { planExtensionRequest, profile }: IUserState = useSelector(selectUser);
  const { offer }: IRegisterContainerQueryParams = queryString.parse(location.search);
  const [formData, setFormDate] = useState<IRegisterFormData>(InitRegistrationRequestPayload);
  // const [payment] = useState({ id: null, type: null, subtype: null });
  // const [isPopupShow, setIsPopupShow] = useState(false);
  const { voucher }: IVoucherState = useSelector(selectVoucher);
  const teacherId: number | null = isLogged
    ? profile.teacherId || null
    : Number(voucher.teacherId) || Number(window.localStorage.getItem(TEACHER_ID)) || null;
  const { currentSchool }: SchoolState = useSelector(selectSchool);
  const { request, isEmailExist }: IRegisterState = useSelector(selectRegister);

  const {
    request: { status: verifyMemberRequestStatus, error: verificationMemberError },
    remainingCount
  } = useSelector(({ verifyMember }: IRootState) => verifyMember);
  const isVerifyMemberRequestFailure = verifyMemberRequestStatus === FAILURE;
  const isVerifyMemberRequestSuccess = verifyMemberRequestStatus === SUCCESS;
  const isVerifyMemberRequestPending = verifyMemberRequestStatus === PENDING;

  const currentOffer: Offer = useSelector(createSelectOfferById(Number(offer)));
  const { offerListRequest }: IOffersState = useSelector(selectOffer);
  const submitButtonRef = useRef(null) as RefObject<HTMLButtonElement>;

  useEffect(() => () => dispatch(REGISTER_RESET_ERROR()), [dispatch]);

  useEffect(() => {
    const prevVipCode = Cookies.get(PREV_VOUCHER_CODE);
    if (prevVipCode) {
      dispatch(VERIFY_VOUCHER_CODE_REQUEST(prevVipCode));
    }
  }, [dispatch]);

  useEffect(() => {
    if (offerListRequest.status === UNCALLED) {
      dispatch(GET_OFFERS_REQUEST());
    }
  }, [dispatch, offerListRequest]);

  useEffect(() => {
    if (offer) {
      dispatch(GET_PAYMENT_METHODS_REQUEST(offer));
    }
  }, [dispatch, offer]);

  useEffect(() => {
    if (!isSSR()) {
      if (currentOffer) {
        localStorage.setItem(SELECTED_OFFER_KEY, JSON.stringify(currentOffer));
      } else {
        localStorage.removeItem(SELECTED_OFFER_KEY);
      }
    }
  }, [currentOffer]);

  useEffect(() => {
    if (teacherId && (!currentSchool || String(teacherId) !== String(currentSchool.teacherId))) {
      dispatch(GET_SCHOOL_BY_ID_REQUEST(teacherId));
    }
  }, [currentSchool, teacherId, dispatch]);

  useEffect(() => {
    if (isVerifyMemberRequestSuccess) {
      const offerId = OFFER_ID_LIST[formData?.category];
      dispatch(REGISTER_REQUEST(composeRegisterPayload(formData, offerId, null, null, formData.tcsNumber, teacherId)));
    }
  }, [isVerifyMemberRequestSuccess, formData, dispatch, teacherId]);

  useEffect(() => {
    dispatch(REMAINING_MEMBER_REQUEST());
  }, [dispatch]);

  const handleVerifyCode = useCallback(
    (data: IRegisterFormData) => {
      setFormDate(data);
      dispatch(VERIFY_MEMBER_REQUEST(composeVerifyCodePayload(data)));
    },
    [dispatch]
  );

  const handleSubmitBtnClick = useCallback(() => {
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  }, [submitButtonRef]);

  const handleLogin = useCallback(() => {
    dispatch(REGISTER_CHECK_EMAIL_RESET());
    dispatch(LOGIN_MODAL_OPEN());
  }, [dispatch]);

  const handleRegisterErrorClose = useCallback(() => {
    dispatch(REGISTER_CHECK_EMAIL_RESET());
  }, [dispatch]);

  const handleVerifyErrorClose = useCallback(() => {
    dispatch(VERIFY_MEMBER_ERROR_RESET());
  }, [dispatch]);

  const validationErrorMessages: { [key: string]: string } = request.error
    ? getErrorMessagesFromResponse(request.error)
    : {};

  return (
    <div className={styles.registration}>
      <Container>
        <Row>
          <Col>
            <div className={styles.formTitle}>{t(isLogged ? 'registerForm.titleLogged' : 'registerForm.title')}</div>
          </Col>
        </Row>
        <Row className={styles.formRegistration}>
          <Col lg={4}>
            <>
              <div className={classnames(styles.planInfo, styles.planInfoTopOffset)}>
                <div className={styles.planInfoItem}>
                  <p className={styles.itemLabel}>{t('registerForm.form.tcs.leftTopTitle')}</p>
                </div>
                <div className={styles.planInfoItem}>
                  <p className={styles.itemLabel}>
                    {t('registerForm.form.tcs.leftBottomTitle', { count: remainingCount })}
                  </p>
                </div>
              </div>
            </>
          </Col>
          {!isLogged && (
            <Col lg={4}>
              <RegisterFormTCS
                submitButtonRef={submitButtonRef}
                onSubmit={handleVerifyCode}
                errorMessages={validationErrorMessages}
              />
              <Button variant="secondary" type="button" onClick={handleSubmitBtnClick}>
                {t('registerForm.form.tcs.registration')}
              </Button>
            </Col>
          )}
        </Row>
      </Container>
      <Loader
        show={request.status === PENDING || planExtensionRequest.status === PENDING || isVerifyMemberRequestPending}
      />
      <RegisterEmailError show={isEmailExist} onHide={handleRegisterErrorClose} onLogin={handleLogin} />
      <RegisterVerificationCodeError
        show={isVerifyMemberRequestFailure}
        onHide={handleVerifyErrorClose}
        errorMessage={verificationMemberError?.message}
      />
    </div>
  );
};

export default RegisterContainerTCS;
