import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { ILeaveExamModalProps } from './LeaveExamModal.types';
import styles from './LeaveExamModal.module.css';
import { P3 } from '../../ui-components/P3/P3';

const LeaveExamModal: React.FC<ILeaveExamModalProps> = ({ show, onHide, goToNextRoute }: ILeaveExamModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal animation show={show} onHide={onHide} size="sm" className={styles.leaveExamModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('leaveExamModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <P3>{t('leaveExamModal.text')}</P3>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="link" onClick={goToNextRoute}>
          {t('leaveExamModal.yes')}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          {t('leaveExamModal.no')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaveExamModal;
