import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import IFinishExamModalProps from './FinishExamModal.types';
import styles from './FinishExamModal.module.css';
import { P3 } from '../../ui-components/P3/P3';

const FinishExamModal: React.FC<IFinishExamModalProps> = ({ show, onHide, onYes }: IFinishExamModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal className={styles.finishExamModal} animation show={show} size="sm" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('finishExamModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <P3>{t('finishExamModal.text')}</P3>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={onYes}>
          {t('finishExamModal.yesBtn')}
        </Button>
        <Button variant="secondary" onClick={onHide}>
          {t('finishExamModal.noBtn')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FinishExamModal;
