import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import bannerImg from '../../img/plan-extension.png';
import Image from '../../ui-components/Image/Image';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import styles from './ConfirmedPlanExtensionPage.module.css';

const ConfirmedPlanExtensionPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.confirmedPage}>
      <Container>
        <Row>
          <Col>
            <div className={styles.confirmedPageContent}>
              <div className={styles.imgContainer}>
                <Image src={bannerImg} width="100%" />
              </div>
              <div className={styles.confirmedPageText}>
                <PageTitle>{t('planExtensionConfirmed.topBlock.title')}</PageTitle>
                <div className={styles.pageTextSmall}>{t('planExtensionConfirmed.topBlock.text1')}</div>
                <h5 className={styles.pageTextSubtitle}>{t('planExtensionConfirmed.topBlock.text2')}</h5>
                <Button className={styles.linkBtn} as={LinkWrapper} variant="link" to="/mycockpit">
                  <i className="icon-arrow" />
                  {t('planExtensionConfirmed.topBlock.link')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmedPlanExtensionPage;
