import React, { memo } from 'react';
import MarkerCluster from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { Marker } from 'react-google-maps';
import { ISchoolCoordinates } from '../../reducer/school.types';
import { ISchoolMarkersTypesProps } from './SchoolMarkers.types';

const SchoolMarkers: React.FC<ISchoolMarkersTypesProps> = ({ schoolCoordinatesList, onClick }) => {
  const handleOnClick = (teacherId: number) => () => onClick(teacherId);

  return (
    <MarkerCluster averageCenter enableRetinaIcons gridSize={40}>
      {schoolCoordinatesList.map((school: ISchoolCoordinates) => (
        <Marker
          key={school.teacherId}
          position={{ lat: Number(school.teacherLatitude), lng: Number(school.teacherLongitude) }}
          onClick={handleOnClick(school.teacherId)}
        />
      ))}
    </MarkerCluster>
  );
};

export default memo(SchoolMarkers);
