import React, { useEffect, useState } from 'react';
import { ITimerProps } from './Timer.types';
import styles from './Timer.module.css';

const getTimeString = (minutes: number, seconds: number): string => {
  const min = minutes > 9 ? minutes.toString() : `0${minutes}`;
  const sec = seconds > 9 ? seconds.toString() : `0${seconds}`;
  return `${min}:${sec}`;
};

const Timer: React.FC<ITimerProps> = ({ startTime, duration, onFinish }: ITimerProps) => {
  const [timeStr, setTimeStr] = useState('');
  const isFinished: boolean = timeStr === '00:00';

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime: number = +new Date();
      const resultTime: number = startTime ? duration - currentTime + startTime : duration;
      const minutes: number = resultTime > 0 ? Math.trunc(resultTime / 60000) : 0;
      const seconds: number = resultTime > 0 ? Math.trunc((resultTime % 60000) / 1000) : 0;

      setTimeStr(getTimeString(minutes, seconds));
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime, setTimeStr, duration, timeStr]);

  useEffect(() => {
    if (isFinished && onFinish) {
      onFinish();
    }
  }, [isFinished, onFinish]);

  return <div className={styles.timer}>{timeStr}</div>;
};

export default Timer;
