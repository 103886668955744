import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { ITheoryGlossaryProps } from './TheoryGlossary.types';
import styles from './TheoryGlossary.module.css';

export const TheoryGlossary: React.FC<ITheoryGlossaryProps> = ({
  onChangePathname,
  content,
  pathname
}: ITheoryGlossaryProps) => {
  const handleChange = useCallback(() => {
    onChangePathname(pathname);
  }, [pathname, onChangePathname]);

  return (
    <div className={styles.glossaryListItem}>
      <Button className={styles.glossaryLink} variant="link" onClick={handleChange}>
        {content}
      </Button>
    </div>
  );
};
