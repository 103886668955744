import React, { useState, useEffect, useCallback } from 'react';
import ModalAccessibility from '../components/ModalAccessibility/ModalAccessibility';
import { offlineMode } from '../config/firebase.config';

interface IModalAccessibilityContainer {
  isOnline: boolean;
}

const ModalAccessibilityContainer: React.FC<IModalAccessibilityContainer> = ({ isOnline }) => {
  const [modalClosed, setModalClosed] = useState(false);
  const [isMultipleTabs, setIsMultipleTabs] = useState(false);
  const [isBrowserNotSupported, setIsBrowserNotSupport] = useState(false);

  useEffect(() => {
    if (offlineMode) {
      offlineMode.catch(err => {
        switch (err.code) {
          case 'failed-precondition':
            setIsMultipleTabs(true);
            break;
          case 'unimplemented':
            setIsBrowserNotSupport(true);
            break;
          default:
            console.error(err);
        }
      });
    }
  }, []);

  const onHide = useCallback(() => {
    setModalClosed(true);
    setIsMultipleTabs(false);
    setIsBrowserNotSupport(false);
  }, [setIsMultipleTabs, setIsBrowserNotSupport]);

  return (
    <>
      {!modalClosed && (
        <ModalAccessibility
          show={!isOnline}
          onHide={onHide}
          isMultipleTabs={isMultipleTabs}
          isOffline={!isOnline}
          isBrowserNotSupported={isBrowserNotSupported}
        />
      )}
    </>
  );
};

export default ModalAccessibilityContainer;
