import { handleActions } from 'redux-actions';
import { TopicState } from './topic.types';
import { GET_TOPICS_REQUEST, GET_TOPICS_FAILURE, GET_TOPICS_SUCCESS } from '../actions/topic.action';
import { SUCCESS, PENDING, FAILURE } from '../constants/store.constants';
import { defaultRequestStatus } from './fetch.types';

const defaultState: TopicState = {
  topics: [],
  topicsRequest: defaultRequestStatus
};

const reduces = handleActions(
  {
    [GET_TOPICS_REQUEST]: state => ({
      ...state,
      topicsRequest: { status: PENDING, error: null }
    }),
    [GET_TOPICS_FAILURE]: action => ({
      ...defaultState,
      topicsRequest: { status: FAILURE, error: action.payload }
    }),
    [GET_TOPICS_SUCCESS]: (state, action) => ({
      ...state,
      topicsRequest: { status: SUCCESS, error: null },
      topics: action.payload.topics
    })
  },
  defaultState
);

export default reduces;
