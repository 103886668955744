import { db } from '../config/firebase.config';
import { dissoc, compose, setObjectValue, objectCopy, curry } from '../utils/utils';

/** create new node because we removed old questions, * */
const LIVES_MODE_NODE = 'livesMode2023';

const updateBestScore = (objectSnapshot, scoreObject) => {
  // eslint-disable-next-line no-nested-ternary
  return objectSnapshot?.bestScore !== undefined
    ? scoreObject.score > objectSnapshot?.bestScore
      ? scoreObject.score
      : objectSnapshot.bestScore
    : scoreObject.score;
};

export const saveLivesGameResult = async (userId, difficulty: string, scoreObject, answerList) => {
  const correctSavePatch = await db
    .collection('user')
    .doc(`${userId}`)
    .collection('game')
    .doc(LIVES_MODE_NODE);

  const docData = await correctSavePatch.get();
  const objectSnapshot = docData.data();

  if (objectSnapshot?.[`${difficulty}BestScore`]) {
    const difficultyBestScore = objectSnapshot?.[`${difficulty}BestScore`];
    if (difficultyBestScore < scoreObject.score) {
      await correctSavePatch.update({
        [`${difficulty}BestScore`]: scoreObject.score,
        bestScore: updateBestScore(objectSnapshot, scoreObject)
      });
    }
  } else if (objectSnapshot) {
    await correctSavePatch.update({
      [`${difficulty}BestScore`]: scoreObject.score,
      bestScore: updateBestScore(objectSnapshot, scoreObject)
    });
  } else {
    await correctSavePatch.set({
      [`${difficulty}BestScore`]: scoreObject.score,
      bestScore: scoreObject.score
    });
  }

  await correctSavePatch.collection(difficulty).add({
    ...scoreObject,
    answerList
  });
};

export const getLivesGameScore = async (userId, difficulty: string) => {
  const correctPatch = await db
    .collection('user')
    .doc(`${userId}`)
    .collection('game')
    .doc(LIVES_MODE_NODE);

  const docData = await correctPatch?.get();
  const bestScoresObj = docData.data();

  const listOfScoresByMode = await correctPatch
    .collection(difficulty)
    .orderBy('startTime', 'desc')
    .limit(10)
    .get();

  const listOfScores = listOfScoresByMode.docs.map(item => ({
    ...item.data()
  }));

  return {
    listOfScores,
    bestScoresObj
  };
};

export const clearGameHistory = async (userId, difficulty: string) => {
  if (userId) {
    const correctPatch = await db
      .collection('user')
      .doc(`${userId}`)
      .collection('game')
      .doc(LIVES_MODE_NODE);

    const docData = await correctPatch?.get();
    const objectSnapshot = docData.data();
    const filterAllExceptBestScore = dissoc('bestScore');
    const clearBestValueByDifficulty = setObjectValue(`${difficulty}BestScore`, null);
    const findMaxObjectValue = curry(object => {
      const arr: number[] = Object.values(object);
      return Math.max(...arr);
    });
    const setBestValue = curry(xs => {
      return setObjectValue('bestScore', findMaxObjectValue(xs), xs);
    });

    const correctNewBestScoreObject = compose(
      objectCopy,
      filterAllExceptBestScore,
      clearBestValueByDifficulty,
      setBestValue
    )(objectSnapshot);
    const gameHistoryByDifficulty = await correctPatch.collection(difficulty).get();

    gameHistoryByDifficulty.docs.forEach(game => {
      game.ref.delete();
    });
    await correctPatch.set({ ...correctNewBestScoreObject });
  }
};
