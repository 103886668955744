import React from 'react';
import classNames from 'classnames';
import { IBottomDetailsColumnProps } from './BottomDetailsColumn.types';
import styles from './BottomDetailsColumn.module.css';

export const BottomDetailsColumn: React.FC<IBottomDetailsColumnProps> = ({
  children,
  className
}: IBottomDetailsColumnProps) => {
  return <div className={classNames(styles.bottomDetailsColumn, className)}>{children}</div>;
};
