import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContentTitle } from '../../ui-components/PageContentTitle/PageContentTitle';
import Teacher3dCarousel from '../../components/Teacher3dCarousel/Teacher3dCarousel';

const VIDEO_LIST = [
  { src: '/content/3dteacher/3_1.mp4', text: 'lightSignal.text_3_1' },
  { src: '/content/3dteacher/3_2.mp4', text: 'lightSignal.text_3_2' },
  { src: '/content/3dteacher/3_3.mp4', text: 'lightSignal.text_3_3' },
  { src: '/content/3dteacher/3_4.mp4', text: 'lightSignal.text_3_4' },
  { src: '/content/3dteacher/3_5.mp4', text: 'lightSignal.text_3_5' },
  { src: '/content/3dteacher/3_6.mp4', text: 'lightSignal.text_3_6' },
  { src: '/content/3dteacher/3_7.mp4', text: 'lightSignal.text_3_7' }
];

const LightSignal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageContentTitle>{t('lightSignal.title')}</PageContentTitle>
      <Teacher3dCarousel srcArray={VIDEO_LIST} />
    </>
  );
};

export default LightSignal;
