import React from 'react';
import { IAvatarProps } from './Avatar.types';
import styles from './Avatar.module.css';
import useImgFetchStatus from '../../hooks/useImgFetchStatus';

const Avatar: React.FC<IAvatarProps> = ({ src, alt = '' }: IAvatarProps) => {
  const { isFetched } = useImgFetchStatus(src || '');
  return (
    <div className={styles.avatar}>
      {src && isFetched ? (
        <img className={styles.avatarImg} src={src} alt={alt} />
      ) : (
        <span className={styles.avatarText}>
          {alt
            .split(' ')
            .filter(item => typeof item[0] === 'string')
            .map(item => item[0].toUpperCase())
            .join('')}
        </span>
      )}
    </div>
  );
};

export default Avatar;
