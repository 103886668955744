import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IExamInfoProps } from './ExamInfo.types';
import { IUserCategory } from '../../reducer/user.types';
import styles from './ExamInfo.module.css';

export const ExamInfo: React.FC<IExamInfoProps> = ({ className, userCategory, selectedCategory }: IExamInfoProps) => {
  const { t } = useTranslation();
  const categoryInfo: IUserCategory | null = (userCategory && userCategory[selectedCategory]) || null;

  return (
    <div className={classNames(className)}>
      {categoryInfo && (
        <div className={styles.examInfoCard}>
          <div className={styles.examCardItem}>
            <div className={styles.cardLabel}>{t('examInfo.maxPoints')}</div>
            <div className={styles.cardValue}>{categoryInfo.maximumPoints}</div>
          </div>
          <div className={styles.examCardItem}>
            <div className={styles.cardLabel}>{t('examInfo.failurePoints')}</div>
            <div className={styles.cardValue}>{categoryInfo.allowedErrorPoints}</div>
          </div>
          <div className={styles.examCardItem}>
            <div className={styles.cardLabel}>{t('examInfo.question')}</div>
            <div className={styles.cardValue}>{categoryInfo.questionsCount}</div>
          </div>
          <div className={styles.examCardItem}>
            <div className={styles.cardLabel}>{t('examInfo.time')}</div>
            <div className={styles.cardValue}>{`${categoryInfo.period} min`}</div>
          </div>
        </div>
      )}
    </div>
  );
};
