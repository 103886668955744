import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExamHistoryTable from '../../components/ExamHistoryTable/ExamHistoryTable';
import styles from './ExamResultHistoryContainer.module.css';
import { useGetDataLoadingStatusStore } from '../../hooks/useDataLoadingFromStore';
import { GET_USER_EXAM_RESULTS_REQUEST } from '../../actions/user.action';
import { IRootState } from '../../reducer';
import { ScoreCard } from '../../ui-components/ScoreCard/ScoreCard';
import { LoaderWrapper } from '../../ui-components/LoaderWrapper/LoaderWrapper';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';

const ExamHistoryContainer: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currentCategory = useSelector(({ user }: IRootState) => user.profile.currentCategory);
  const { isLoaded: isUserProfileLoaded } = useGetDataLoadingStatusStore('user.userRequest.status');
  const { isFetching } = useGetDataLoadingStatusStore('user.examResultRequest.status');
  const { lastExams, bestResult } = useSelector(({ user }: IRootState) => user.examResults);

  useEffect(() => {
    if (isUserProfileLoaded && !lastExams.length) {
      dispatch(GET_USER_EXAM_RESULTS_REQUEST({ currentCategory }));
    }
  }, [dispatch, currentCategory, isUserProfileLoaded, lastExams.length]);

  return (
    <Container className={styles.container}>
      <Row>
        <Col className={styles.bestScopeContainer}>
          <ScoreCard>
            <ScoreCard.Label text={t('examHistoryPage.yourBestScore')} />
            <LoaderWrapper show={isFetching} classStyles={styles.loaderWrapper}>
              <ScoreCard.Label
                className={styles.scoreCategory}
                text={`${t('examHistoryPage.selectedCategory')} ${currentCategory}`}
              />
              <ScoreCard.ScoreContainer
                score={bestResult?.truePointCount}
                dontHaveScoreText={t('examHistoryPage.yourBestScore')}
              />
              {bestResult.examId && (
                <LinkWrapper to={`/exam/${bestResult.examId}`}>
                  <div>{t('examHistoryPage.show')}</div>
                </LinkWrapper>
              )}
            </LoaderWrapper>
          </ScoreCard>
        </Col>
      </Row>
      <Row>
        <Col>
          <ExamHistoryTable isDataLoading={isFetching} examHistory={lastExams} />
          <div className={styles.backButtonWrapper}>
            <Button variant="secondary" as={LinkWrapper} to="/">
              {t('examHistoryPage.goBack')}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(ExamHistoryContainer);
