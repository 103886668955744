import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import useReactRouter from 'use-react-router';
import { selectLogin } from '../reducer/login.selectors';
import usePageStates from '../hooks/usePageStates';
import { LOGIN_MODAL_OPEN, LOGOUT_MODAL_OPEN } from '../actions/login.action';
import HeaderMenu from '../components/HeaderMenu/HeaderMenu';

const HeaderMenuContainer: React.FC = () => {
  const { isLogged } = useSelector(selectLogin);
  const { isDemo } = usePageStates();
  const dispatch = useDispatch();
  const routeProps: RouteComponentProps = useReactRouter();

  const handleLoginClick = useCallback(() => {
    dispatch(LOGIN_MODAL_OPEN());
  }, [dispatch]);

  const handleLogoutClick = useCallback(() => {
    dispatch(LOGOUT_MODAL_OPEN());
  }, [dispatch]);

  return (
    <HeaderMenu
      routeProps={routeProps}
      isLogged={isLogged}
      onLoginClick={handleLoginClick}
      onLogoutClick={handleLogoutClick}
      isDemo={isDemo && !isLogged}
    />
  );
};

export default HeaderMenuContainer;
