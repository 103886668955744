import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../reducer/user.selectors';
import Teacher3dPage from '../../pages/Teacher3dPage/Teacher3dPage';
import { ITeacher3dPageContainerProps } from './Teacher3dPageContainer.types';

const Teacher3dPageContainer: React.FC<ITeacher3dPageContainerProps> = ({ match }: ITeacher3dPageContainerProps) => {
  const currentLanguage = useSelector(selectLanguage);

  return <Teacher3dPage match={match} currentLanguage={currentLanguage} />;
};

export default Teacher3dPageContainer;
