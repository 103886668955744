import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CategoryIcon from '../CategoryIcon/CategoryIcon';
import PromoIcon from '../PromoIcon/PromoIcon';
import HomeCarousel from '../HomeCarousel/HomeCarousel';
import { IPromoContentProps } from './PromoContent.types';
import styles from './PromoContent.module.css';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { LinkWrapper } from '../../ui-components/LinkWrapper/LinkWrapper';
import { imageAbsolutePath } from '../../utils/utils';
import { useGetSelectedColorTheme } from '../../hooks/useGetSelectedColorTheme';

const rulesImage = imageAbsolutePath('key-visual.jpeg');
const rulesImageDark = imageAbsolutePath('key-visualDark.png');

const CategoryIconBar = (
  <div className="categoryIconBarHome">
    <CategoryIcon icon="icon-car" level="B" badge={false} />
    <CategoryIcon icon="icon-motorcycle" level="A, A1" badge={false} />
    <CategoryIcon icon="icon-tractor" level="F, G" badge={false} />
    <CategoryIcon icon="icon-e-bike" level="M" badge />
  </div>
);

const PromoContent: React.FC<IPromoContentProps> = ({
  onLoginClick,
  onLogoutClick,
  isLogged,
  currentLanguage
}: IPromoContentProps) => {
  const { t } = useTranslation();

  const { isDarkTheme } = useGetSelectedColorTheme();

  const rulesImageSrc = isDarkTheme ? rulesImageDark : rulesImage;

  return (
    <div className={styles.promoContent}>
      <div className={styles.promoItem}>
        <div className={styles.rules}>
          <img className={styles.rulesImg} src={rulesImageSrc} alt="banner" />
          <div className={styles.rulesList}>{CategoryIconBar}</div>
        </div>
      </div>
      <div className={styles.promoItem}>
        <PageTitle>{t('homePage.learnTheoryTitle')}</PageTitle>
        <p className={styles.subtitle}>{t('homePage.subtitle')}</p>
        <div className={styles.iconsList}>
          <PromoIcon src="2" currentLanguage={currentLanguage} alt="badge" />
        </div>
        <div className={styles.rulesListMobile}>{CategoryIconBar}</div>
        <HomeCarousel />
        {isLogged ? (
          <div className={styles.btnList}>
            <Button className={styles.loginBtnMobile} variant="secondary" onClick={onLogoutClick}>
              {t('app.btn.logout')}
            </Button>
          </div>
        ) : (
          <div className={styles.btnList}>
            <Button className={styles.loginBtnMobile} variant="secondary" onClick={onLoginClick}>
              {t('app.btn.login')}
            </Button>
            <LinkWrapper className="btn btn-primary" to="/register">
              {t('homePage.btn.deals')}
            </LinkWrapper>
            <LinkWrapper className="btn btn-outline-primary" to="/mycockpitdemo">
              {t('homePage.btn.demo')}
            </LinkWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoContent;
