import { IRegisterFormData } from './RegisterFormTCS.types';

export const InitRegistrationRequestPayload: IRegisterFormData = {
  title: '',
  tcsNumber: '',
  category: '',
  firstname: '',
  lastname: '',
  email: '',
  confirmEmail: '',
  address: '',
  phone: '',
  zip: '',
  city: '',
  toc: false,
  news: false,
  birthDate: ''
};
