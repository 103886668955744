import React, { MouseEvent, memo } from 'react';
import { useSelector } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { selectLanguage } from '../../reducer/user.selectors';
import { scrollToAnchor } from '../../utils/utils';
import { ILinkWrapperProps } from './LinkWrapper.types';

export const LinkWrapper: React.FC<LinkProps & ILinkWrapperProps> = memo(props => {
  const { location } = useReactRouter();
  const language = useSelector(selectLanguage);

  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (typeof props.onClick === 'function') {
      props.onClick(event);
    } else {
      event.preventDefault();
    }
    if (typeof props.to === 'string') {
      scrollToAnchor(props.to);
    }
  };

  if (typeof props.to === 'string' && props.to.startsWith('#')) {
    return <Link {...props} to={{ ...location, hash: props.to }} onClick={handleOnClick} />;
  }

  // add string for resolving SSR warning (warning if set boolean)
  /* eslint-disable-next-line */
  const linkUrl = props.withoutlanguageprefix
    ? JSON.parse(props.withoutlanguageprefix)
      ? `${props.to}`
      : `/${language}${props.to}`
    : `/${language}${props.to}`;
  return <Link {...props} to={linkUrl} />;
});
