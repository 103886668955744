import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { P3 } from '../../ui-components/P3/P3';
import styles from './Teacher3dDisplay.module.css';

const Teacher3dDisplay = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.pageTopContent}>
      <Container>
        <Row>
          <Col>
            <PageTitle>{t('teacher3dDisplay.title')}</PageTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <P3>{t('teacher3dDisplay.subtitle1')}</P3>
          </Col>
          <Col lg={{ span: 4, offset: 1 }}>
            <P3>{t('teacher3dDisplay.subtitle2')}</P3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Teacher3dDisplay;
