/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from 'react-bootstrap';
import { LinkWrapper } from '../../../ui-components/LinkWrapper/LinkWrapper';
import styles from './TheoretischeAutopruefungPage.module.css';

const TheoretischeAutopruefungPage = () => {
  return (
    <div className={styles.root}>
      <Container>
        <h1>Online für die theoretische Autoprüfung lernen – mit »theorie24«</h1>

        <img
          className={styles.mainImage}
          src="/content/tour/fragen.png"
          alt="theoretische Autoprüfung – theorie24-App (Basistheorie)"
          width="400"
          height="250"
        />
        <p>
          Du möchtest <b>für die theoretische Autoprüfung lernen</b> und dabei auf die beste Lern-App zurückgreifen, die
          es in der Schweiz gibt? Dann bist du hier richtig! Mit »theorie24« sicherst du dir eine bewährte App, die dir
          das notwendige Wissen spielend leicht vermittelt. Du kannst jederzeit online für die theoretische Autoprüfung
          lernen und dich optimal vorbereiten. Informiere dich jetzt über unsere aktuellen Programmpakete und treffe
          deine Wahl!
        </p>

        <h2>Selbstbewusst in die theoretische Autoprüfung gehen</h2>

        <p>
          Manche Fahrschüler sind am Tag der theoretischen Autoprüfung so nervös, dass sie nur noch ein Schatten ihrer
          selbst sind. Das muss nicht sein: Wenn du dich mit »theorie24« gewissenhaft vorbereitest, kannst du{' '}
          <b>selbstbewusst in die Prüfung gehen</b>. Unsere Lern-App wurde speziell für die Theorie-Vorbereitung
          entwickelt und enthält alle Original-Prüfungsfragen. Der Lernstoff ist so aufbereitet, dass du ihn leicht
          aufnehmen und in der Prüfung wiedergeben kannst.
        </p>

        <h3>Jetzt eine Tour starten!</h3>

        <p>
          Informiere dich jetzt über die vielfältigen Optionen, die dir »theorie24« bietet! Auf unserer Webseite kannst
          du jederzeit eine <LinkWrapper to="/about">Tour</LinkWrapper> starten und dir die unterschiedlichen Sektionen
          der App ansehen. Dazu gehören beispielsweise die Prüfungsfragen, der Prüfungssimulator und die gesetzliche
          Basistheorie. Danach kannst du dich frei entscheiden, ob du mit »theorie24« für die theoretische Autoprüfung
          lernen willst. Eine kurze Einführung in die App gibt dir das folgende Video.
        </p>

        <iframe
          title="Iframe"
          className={styles.video}
          width="645"
          height="483"
          src="https://www.youtube.com/embed/YRgQHvueUlM"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <h3>VIP-Card: Das ideale Geschenk für Fahrschüler</h3>

        <p>
          Als Geschenk zum Geburtstag oder zu Weihnachten empfiehlt sich unsere <b>VIP-Card</b>. Diese schaltet den
          Zugang zur Lern-App für 60, 90 oder 365 Tage frei. Die VIP-Card ist direkt in unserem Online-Shop oder gegen
          Bezahlung beim Fahrlehrer erhältlich. Übrigens: Beim Kauf einer DVD-Rom oder eines USB-Sticks erhältst du
          einen VIP-Code gratis. Damit kannst du 7 Tage lang mit allen Endgeräten online auf die Lern-App zugreifen.
        </p>

        <h3>Unsere Programmpakete im Überblick</h3>

        <p>
          Du hast dich entschlossen, mit »theorie24« für die theoretische Autoprüfung zu lernen? Dann hast du die Qual
          der Wahl: Wir bieten dir gleich{' '}
          <b>
            <LinkWrapper to="/offers">5 Pakete</LinkWrapper>
          </b>{' '}
          an, unter denen du wählen kannst. Die günstigste Version ist die „Refresher“-Variante (CHF 9), die du 7 Tage
          lang nutzen kannst. Wenn du einen Monat Zeit zum Lernen brauchst, ist die „Fast Track“-Variante (CHF 19) die
          richtige.
        </p>

        <img
          className={styles.mainImage}
          src="/content/tour/simulieren.png"
          alt="theoretische Autoprüfung – Multiple Choice-Frage"
          width="400"
          height="250"
        />

        <p>
          Falls du länger für die theoretische Autoprüfung lernen möchtest, haben wir ebenfalls passende Angebote für
          dich: Die Versionen „Optimum“ und „Optimum+“ sind 2 bzw. 3 Monate nutzbar. Die Top-Variante „Safe“ kann sogar
          ein ganzes Jahr lang genutzt werden. So kannst du vollkommen stressfrei für die theoretische Autoprüfung
          lernen und dich optimal vorbereiten. Registriere dich noch heute!
        </p>
      </Container>
    </div>
  );
};

export default TheoretischeAutopruefungPage;
