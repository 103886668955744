import { useEffect, useState } from 'react';
import useThrottleCallback from './useThrottleCallback';
import { isSSR } from '../utils/utils';

const throttleMs = 300;

const getWidth = (): number => (isSSR() ? 1023 : window.innerWidth);
const getHeight = (): number => (isSSR() ? 767 : window.innerHeight);

const getSize = (): IUseDeviseSizes => ({
  width: getWidth(),
  height: getHeight()
});

export interface IUseDeviseSizes {
  width: number;
  height: number;
}

export default function useDeviceSizes(): IUseDeviseSizes {
  const [sizes, setSizes] = useState<IUseDeviseSizes>(getSize());

  const handleResize = useThrottleCallback(() => setSizes(getSize()), [setSizes], throttleMs);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return sizes;
}
