import { handleActions } from 'redux-actions';
import { GAME_LIVE_NAME_LONG, GAME_TYPE_LIVES } from '../constants';
import { IGameScoreState, IScoreLivesModeAction, IGetScoreSuccessAction } from './gameScore.types';
import {
  GET_SCORE_REQUEST,
  SET_SCORE_LIVES_MODE,
  GET_SCORE_FAILURE,
  GET_SCORE_SUCCESS,
  UPDATE_HISTORY_ANSWER_LIST,
  CLEAR_GAME_SCORE_HISTORY_REQUEST,
  CLEAR_GAME_SCORE_HISTORY_SUCCESS,
  CLEAR_GAME_SCORE_HISTORY_FAILURE
} from '../actions/gameScore.action';
import { defaultRequestStatus } from './fetch.types';
import { FAILURE, PENDING, SUCCESS } from '../constants/store.constants';
import { SET_GAME_LIVES_MODE } from '../actions/game.action';
import { IGameLivesModeAction } from './game.types';

const defaultState: IGameScoreState = {
  type: GAME_TYPE_LIVES,
  livesMode: GAME_LIVE_NAME_LONG,
  scoreRequest: defaultRequestStatus,
  clearGameHistoryRequest: defaultRequestStatus,
  bestScoresObj: null,
  listOfScores: null,
  historyAnswerList: []
};

const reduces = handleActions(
  {
    [SET_SCORE_LIVES_MODE]: (state, action: IScoreLivesModeAction) => ({
      ...state,
      livesMode: action.payload
    }),
    [SET_GAME_LIVES_MODE]: (state, action: IGameLivesModeAction) => {
      return {
        ...state,
        livesMode: action.payload.livesMode
      };
    },
    [GET_SCORE_REQUEST]: state => ({
      ...state,
      scoreRequest: { status: PENDING, error: null }
    }),
    [GET_SCORE_SUCCESS]: (state, action: IGetScoreSuccessAction) => ({
      ...state,
      ...action.payload,
      scoreRequest: { status: SUCCESS, error: null }
    }),
    [GET_SCORE_FAILURE]: (state, action) => ({
      ...state,
      scoreRequest: { status: FAILURE, error: action.payload }
    }),
    [UPDATE_HISTORY_ANSWER_LIST]: (state, action) => ({
      ...state,
      historyAnswerList: action.payload
    }),
    [CLEAR_GAME_SCORE_HISTORY_REQUEST]: state => ({
      ...state,
      clearGameHistoryRequest: { status: PENDING, error: null }
    }),
    [CLEAR_GAME_SCORE_HISTORY_SUCCESS]: state => ({
      ...state,
      clearGameHistoryRequest: { status: SUCCESS, error: null }
    }),
    [CLEAR_GAME_SCORE_HISTORY_FAILURE]: (state, action) => ({
      ...state,
      clearGameHistoryRequest: { status: FAILURE, error: action.payload }
    })
  },
  defaultState
);

export default reduces;
