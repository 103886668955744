import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import OfferCardContainer from '../../containers/OfferCardContainer';
import VipCard from '../../components/VipCard/VipCard';
import VipCardMobile from '../../components/VipCardMobile/VipCardMobile';
import { PageText } from '../../ui-components/PageText/PageText';
import { PageTitle } from '../../ui-components/PageTitle/PageTitle';
import { selectLogin } from '../../reducer/login.selectors';
import { LoginState } from '../../reducer/login.types';
import { selectLanguage } from '../../reducer/user.selectors';
import styles from './OffersPage.module.css';
import { P3 } from '../../ui-components/P3/P3';
import PromoIcon from '../../components/PromoIcon/PromoIcon';

const TickListItem: React.FC<{ text: string }> = ({ text }: { text: string }) => {
  return (
    <li>
      <FontAwesomeIcon icon={faCheck} size="lg" />
      {text}
    </li>
  );
};

const OffersPage: React.FC = () => {
  const { t } = useTranslation();
  const { history, location } = useReactRouter();
  const { isLogged }: LoginState = useSelector(selectLogin);
  const currentLanguage = useSelector(selectLanguage);

  // fix issue with login in offers page
  useEffect(() => {
    if (isLogged) {
      history.replace(location.pathname.replace('/offers', '/extend-offers'));
    }
  }, [isLogged, history, location]);

  return (
    <>
      <div className={styles.offersPageText}>
        <Container>
          <Row>
            <Col lg={12}>
              <PageTitle className={styles.mainTitle}>{t('offersPage.title')}</PageTitle>
            </Col>
            <Col lg={12}>
              <div className={styles.iconsList}>
                <PromoIcon src="2" currentLanguage={currentLanguage} alt="badge" />
              </div>
            </Col>
            <Col lg={12}>
              <div className={styles.list}>
                <h6 className={styles.listTitle}>{t('offersPage.saleContent.title1')}</h6>
                <ul>
                  <TickListItem text={t('offersPage.saleContent.listText1')} />
                  <TickListItem text={t('offersPage.saleContent.listText2')} />
                  <TickListItem text={t('offersPage.saleContent.listText3')} />
                  <TickListItem text={t('offersPage.saleContent.listText4')} />
                  <TickListItem text={t('offersPage.saleContent.listText5')} />
                  <TickListItem text={t('offersPage.saleContent.listText6')} />
                  <TickListItem text={t('offersPage.saleContent.listText7')} />
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.offerPageCard}>
        <OfferCardContainer showTabledView />
      </div>
      <div className={styles.furtherAdvantages}>
        <Container>
          <Row>
            <Col>
              <div className={styles.list}>
                <h6 className={styles.listTitle}>{t('offersPage.saleContent.title2')}</h6>
                <ul>
                  <TickListItem text={t('offersPage.saleContent.listText8')} />
                  <TickListItem text={t('offersPage.saleContent.listText9')} />
                  <TickListItem text={t('offersPage.saleContent.listText10')} />
                  <TickListItem text={t('offersPage.saleContent.listText11')} />
                  <TickListItem text={t('offersPage.saleContent.listText12')} />
                  <TickListItem text={t('offersPage.saleContent.listText13')} />
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.offerPageVip}>
        <Container>
          <Row>
            <Col>
              <div className={styles.offerPageContent}>
                <div className={styles.offerPageItem}>
                  <h3 className="pageSubtitle">{t('offersPage.vipcodeTitle')}</h3>
                  <div className={styles.offerVipCard}>
                    <VipCard />
                  </div>
                  <VipCardMobile />
                </div>
                <Card className={styles.offerCard}>
                  <Card.Body>
                    <Card.Title>{t('offersPage.giveAccess.title')}</Card.Title>
                    <Card.Text>{t('offersPage.giveAccess.subtitle')}</Card.Text>
                  </Card.Body>
                  <Card.Footer className={styles.cardFooterSingle}>
                    <Card.Link
                      className={styles.offerFooterLink}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={t('offersPage.offersLink')}
                    >
                      {t('offersPage.giveAccess.button')}
                    </Card.Link>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.pageTextContainer}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className={styles.pageTextList}>
                <P3>{t('offersPage.subtitle1')}</P3>
                <P3>{t('offersPage.subtitle2')}</P3>
              </div>
              <div className={styles.pageTextListMobile}>
                <PageText>{t('offersPage.subtitle1')}</PageText>
                <PageText>{t('offersPage.subtitle2')}</PageText>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OffersPage;
