import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import HomeCards from '../../components/HomeCards/HomeCards';
import Ads from '../../components/Ads/Ads';
import PromoContentContainer from '../../containers/PromoContentContainer';
import VipContent from '../../components/VipContent/VipContent';
import SocialLinkList from '../../components/SocialLinkList/SocialLinkList';
import HomeCarouselMobile from '../../components/HomeCarouselMobile/HomeCarouselMobile';
import styles from './HomePage.module.css';

const HomePage: React.FC = () => (
  <div>
    <div className={styles.sectionPromo}>
      <Container>
        <Row>
          <Col>
            <PromoContentContainer />
          </Col>
        </Row>
      </Container>
    </div>
    <div className={styles.mobileCarousel}>
      <Container>
        <Row>
          <Col>
            <HomeCarouselMobile />
          </Col>
        </Row>
      </Container>
    </div>
    <div className={styles.sectionAdsMobile}>
      <Ads idx={1} />
    </div>
    <div className={styles.sectionVip}>
      <Container>
        <VipContent />
      </Container>
    </div>
    <div className={styles.sectionCard}>
      <Container>
        <HomeCards />
      </Container>
    </div>
    {/* <div className={styles.sectionOnlineShop}> */}
    {/*  <Container> */}
    {/*    <HomePageShop /> */}
    {/*  </Container> */}
    {/* </div> */}
    <div className={styles.sectionSocialMobile}>
      <Container>
        <Row>
          <Col>
            <SocialLinkList />
          </Col>
        </Row>
      </Container>
    </div>
    <Ads />
  </div>
);

export default HomePage;
