import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './LngDropDown.module.css';
import { ILngDropDownProps } from './LngDropDown.types';
import { defaultLanguage } from '../../locales/locale';
import languageIcons from '../../img/sprite.svg';
import { primaryLangs } from '../../constants/primaryLangs';

const getLanguageIcong = (toggleState, questionLng, userLng) => {
  if (typeof toggleState === 'boolean') {
    return toggleState ? userLng : questionLng;
  }

  return 'multilang';
};

const getPrioLangs = questionLngList => {
  const langs: Array<string> = [];

  // add prio langs but not current user lang
  primaryLangs.forEach(language => {
    if (questionLngList.includes(language)) {
      langs.push(language);
    }
  });

  // add other langs
  questionLngList.forEach(language => {
    if (!primaryLangs.includes(language)) {
      langs.push(language);
    }
  });

  return langs;
};

const LngDropDown: React.FC<ILngDropDownProps> = ({
  iconCls,
  onLngToggleClick,
  onToggleChangeLng,
  languageToggleState,
  questionLngList,
  userLanguage,
  lng = defaultLanguage
}: ILngDropDownProps) => (
  <Dropdown className={styles.barBtn} as={ButtonGroup}>
    <button type="button" className={classNames(iconCls)} onClick={onLngToggleClick}>
      <svg className="flag">
        <use xlinkHref={`${languageIcons}#${getLanguageIcong(languageToggleState, lng, userLanguage)}`} />
      </svg>
    </button>
    <Dropdown.Toggle className={styles.dropDownToggle} id="dropdown-basic" />
    <Dropdown.Menu className={styles.dropMenu}>
      {getPrioLangs(questionLngList).map(language => (
        <Dropdown.Item
          key={language}
          onClick={() => {
            onToggleChangeLng(language);
          }}
        >
          <svg className="flag">
            <use xlinkHref={`${languageIcons}#${language}`} />
          </svg>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

export default LngDropDown;
