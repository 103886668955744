import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../reducer';
import styles from './ExamBgContainer.module.css';
import InstructorContainer from '../InstructorContainer/InstructorContainer';
import image from '../../img/key-visual.jpeg';
import { RulesGroupContent } from '../../ui-components/RulesGroupContent/RulesGroupContent';
import CategoryContainer from '../CategoryContainer/CategoryContainer';

const ExamBgContainer: React.FC = () => {
  const isLogged = useSelector(({ login }: IRootState) => login.isLogged);

  if (isLogged) {
    return (
      <div className={styles.examBgContainer}>
        <RulesGroupContent className={styles.examPageContent}>
          <div className={styles.pcInstructor}>
            <InstructorContainer src={image} />
          </div>
          <CategoryContainer isExam />
        </RulesGroupContent>
      </div>
    );
  }
  return (
    <RulesGroupContent className={styles.examPageContent}>
      <div className={styles.pcInstructor}>
        <InstructorContainer src={image} />
      </div>
      <CategoryContainer isExam />
    </RulesGroupContent>
  );
};

export default ExamBgContainer;
